import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
  SaveOutlined,
  SwapOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';

import ecount from '../../ecount';
import useModal from '../../hook/useModal';
import { asyncUpdateDataFetch } from '../../redux/modules/shared/updateData';
import { asyncCreateDataFetch } from '../../redux/modules/shared/createData';

import OrderForm from './OrderForm';
import OrderSaleForm from './OrderSaleForm';
import OrderProductForm from './OrderProductForm';
import { asyncDeleteDataFetch } from '../../redux/modules/shared/deleteData';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

import { OrderProductsContext } from '../../providers/OrderProductsProvider';
import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';
import useChangeInventoryData from '../../hook/useChangeInventoryData';
import useInventoryNotification from '../../hook/useInventoryNotification';

const Event = (props) => {
  const { pageType, orderItems, orderSaleItems, datas } = props;
  const { saveOrder } = ecount();
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const history = useHistory();
  const { id } = useParams();
  const { addInventoryBySales, changeInventoryBySales } =
    useChangeInventoryData();
  const { alertNotification } = useInventoryNotification();

  const orderProductRef = useRef(null);
  const orderSaleRef = useRef(null);

  const [orderData, setOrderData] = useState({});
  const [orderSaleDatas, setOrderSaleDatas] = useState([]);
  const [orderProductDatas, setOrderProductDatas] = useState([]);

  const [dcAccountProductDatas, setDcAccountProductDatas] = useState([]);
  const [loading, setLoading] = useState(false);

  // 선택된 주소 id값
  const [addressIdx, setAddressIdx] = useState('');
  // 선택된 주소
  const [selectedAddress, setSelectedAddress] = useState({});

  // sms를 보냈는지 여부
  const [smsDone, setSmsDone] = useState(false);

  console.log(orderData);

  // context API 사용
  const { orderProducts, orderSales } = useContext(OrderProductsContext);
  // context API의 데이터를 불러옴.
  useEffect(() => {
    if (orderProducts.length > 0 && pageType === 'create') {
      // 판매상품 데이터 불러오기
      const noSortProperty = orderSales.map((data) => ({
        delivery: data.delivery,
        sale_name: data.sale_name,
        sale_code: data.sale_code,
        sale_quantity: data.sale_quantity,
      }));
      setOrderSaleDatas([...noSortProperty]);
      // 출고상품 데이터 불러오기
      setOrderProductDatas([...orderProducts]);
    }
  }, [orderProducts, orderSales, pageType]);

  // 렌더링 할 때, 페이지가 detail 또는 update일 때 기존 데이터를 받아옴.
  useEffect(() => {
    if (
      pageType === 'detail' ||
      pageType === 'update' ||
      window.location.pathname.includes('estimateToOrder')
    ) {
      setOrderData({ ...datas.orderData });
      setOrderSaleDatas([...datas.orderSaleDatas]);
      setOrderProductDatas([...datas.orderProductDatas]);
    }
  }, [pageType, datas]);

  const handleValidation = useCallback(() => {
    // 주문서 항목이 빈 객체일 때 (아무런 값도 입력되지 않았을 때)
    if (Object.keys(orderData).length === 0) {
      alert('주문서를 입력해주세요.');
      return true;
    }
    // 주문서 항목 필수 값이 입력되지 않았을 때
    if (!orderData.account_code) {
      alert('거래처를 선택해주세요.');
      return true;
    }
    if (!orderData.warehouse_code) {
      alert('출하창고를 선택해주세요.');
      return true;
    }
    if (!orderData.admin_code) {
      alert('담당자를 선택해주세요.');
      return true;
    }
    if (!orderData.order_date) {
      alert('주문일자를 선택해주세요.');
      return true;
    }
    // 주문-판매 항목이 빈 배열일 때 (아무런 값도 선택하지 않았을 때)
    if (orderSaleDatas.length === 0) {
      alert('주문-판매 정보가 없습니다. 주문-판매를 추가해주세요.');
      return true;
    }
    // 주문-출고 항목이 빈 배열일 때 (아무런 값도 선택하지 않았을 때)
    if (orderSaleDatas.length === 0) {
      alert('주문-출고 정보가 없습니다. 주문-출고를 추가해주세요.');
      return true;
    }
    // 이미 선택된 주문-판매 항목일 때
  }, [orderData, orderSaleDatas.length]);

  const handleCreate = useCallback(
    (type) => {
      // sms발송 확인
      let confirmSmsDone = true;
      if (!smsDone && type !== 'estimate') {
        confirmSmsDone = window.confirm(
          '결제요청 sms를 전송하지 않았습니다. 계속 진행하시겠습니까?'
        );
      }
      if (!confirmSmsDone) {
        return;
      }

      // 등록 확인
      const confirmCreate = window.confirm(
        '정말 주문/판매 데이터를 등록하시겠습니까?'
      );
      if (!confirmCreate) {
        return;
      }

      // 유효성 검사
      const validationResult = handleValidation();
      if (validationResult === true) {
        return;
      }

      // 견적서 -> 주문서 전환일 경우
      if (window.location.pathname.includes('estimateToOrder')) {
        const confirmChange = window.confirm(
          '견적서 데이터는 삭제되고 주문서로 전환됩니다. 진행하시겠습니까?'
        );
        if (!confirmChange) {
          return;
        }
        const changeToOrder = async () => {
          // 견적서 데이터 삭제 (fk 참조 이슈로 먼저 참조하는 테이블 값들을 먼저 삭제)
          await Promise.all([
            dispatch(
              asyncDeleteDataFetch({ table: 'order_sale', orderId: id })
            ),
            dispatch(
              asyncDeleteDataFetch({ table: 'order_product', orderId: id })
            ),
            dispatch(
              asyncDeleteDataFetch({ table: 'estimate_sent', estimateId: id })
            ),
          ]);
          await dispatch(asyncDeleteDataFetch({ table: 'order', id }));
        };
        changeToOrder();
      }

      // 주문서 (order) id 값
      let orderId = '';
      // orderId를 받기 위한 async함수 생성
      const asyncFunc = async () => {
        // loading
        setLoading(true);

        // 주문서 (order) 추가 함수
        // 필요한 주문서 값만 넣어준다. (id 등 제외)
        const {
          admin_code,
          warehouse_code,
          account_code,
          account_name,
          account_phone,
          deal_type,
          payment_terms,
          order_date,
          delivery_date,
          memo,
          mul_no,
          sms_phone,
        } = orderData;
        const { address } = selectedAddress;
        const post_code = selectedAddress.postCode;
        await dispatch(
          asyncCreateDataFetch({
            table: 'order',
            admin_code,
            warehouse_code,
            account_code,
            account_name,
            account_phone,
            deal_type,
            payment_terms,
            order_date,
            delivery_date,
            memo,
            address,
            post_code,
            mul_no,
            sms_phone,
            payment_status: type === 'estimate' ? '2' : '0',
            address_id: addressIdx,
          })
        )
          .unwrap()
          .then((res) => {
            orderId = res[0].id;
          })
          .catch((err) => {
            alert(
              err.message,
              '주문서 입력을 데이터에 추가하는데 실패했습니다.'
            );
            console.log(err);
          });
        // 견적서일 경우 견적서여부 (estimate_sent) 추가 함수
        if (type === 'estimate') {
          await dispatch(
            asyncCreateDataFetch({
              table: 'estimate_sent',
              estimate_id: orderId,
              is_sent: false,
            })
          )
            .then((res) => console.log(res))
            .catch((err) => {
              console.log(err);
              alert(err.message);
            });
        }
        // 주문-판매 (order_sale) 추가 함수
        await orderSaleDatas.forEach((orderSaleData) => {
          // 필요한 주문-판매 값만 넣어준다. (id값 등 제외)
          const { sale_code, sale_name, sale_quantity } = orderSaleData;
          return dispatch(
            asyncCreateDataFetch({
              table: 'order_sale',
              order_id: orderId,
              sale_code,
              sale_name,
              sale_quantity,
            })
          )
            .unwrap()
            .then()
            .catch((err) => {
              alert(
                err.message,
                '주문-판매 정보를 데이터에 추가하는데 실패했습니다.'
              );
              console.log(err);
            });
        });
        await orderProductDatas.forEach((orderProductData) => {
          // oms 데이터에 저장
          // 필요한 주문-출고 값만 넣어준다. (id값 등 제외)
          const {
            product_code,
            product_name,
            product_price,
            product_VAT_price,
            product_supply_price,
            product_quantity,
            product_warehouse,
            delivery_price,
            discounted_supply,
            discounted_vat,
            discounted_price,
            discount_rate,
            discount,
            discounted,
            is_account_dc,
          } = orderProductData;
          return dispatch(
            asyncCreateDataFetch({
              table: 'order_product',
              order_id: orderId,
              product_code,
              product_name,
              product_quantity,
              product_warehouse,
              delivery_price,
              product_price,
              product_VAT_price,
              product_supply_price,
              discounted_supply,
              discounted_vat,
              discounted_price,
              discount_rate,
              discount,
              discounted,
              is_account_dc,
            })
          )
            .unwrap()
            .then(() => {
              // 성공 모달 띄우기
              openModal({
                type: 'result',
                modalInfo: {
                  modalTitle: type === 'estimate' ? '견적' : '주문/판매',
                  resultStatus: 'success',
                  resultTitle:
                    type === 'estimate'
                      ? '견적서 데이터를 추가했습니다.'
                      : '주문/판매 데이터를 추가했습니다.',
                  resultSubTitle: '',
                  buttonText: '확인',
                },
              });
              // 목록으로 넘어가기
              if (type === 'estimate') {
                history.push('/estimate');
              } else {
                history.push('/order');
              }
            })
            .catch((err) => {
              alert(
                err.message,
                '주문-출고 정보를 데이터에 등록하는데 실패했습니다.'
              );
              console.log(err);
            });
        });
        await dispatch(
          asyncReadFilteredDataFetch({
            table: 'order_product',
            eqKey: 'order_id',
            eqValue: orderId,
          })
        )
          .unwrap()
          .then((res) => console.log(res));
        // ecount 주문서 / 견적서 저장
        saveOrder({ id: orderId });

        // loading
        setLoading(false);
      };
      // async함수 실행
      asyncFunc();
    },
    [
      id,
      dispatch,
      openModal,
      history,
      orderSaleDatas,
      orderProductDatas,
      handleValidation,
      orderData,
      saveOrder,
      selectedAddress,
      smsDone,
      addressIdx,
    ]
  );

  const handleUpdate = useCallback(async () => {
    const validationResult = handleValidation();
    if (validationResult) {
      return;
    }
    const confirmUpdate = window.confirm(
      '정말 주문/판매 데이터를 수정하시겠습니까?'
    );
    if (!confirmUpdate) {
      return;
    }
    try {
      // loading
      setLoading(true);

      // 주문서 (order) 정보
      const {
        admin_code,
        warehouse_code,
        account_code,
        account_name,
        account_phone,
        deal_type,
        payment_terms,
        order_date,
        delivery_date,
        memo,
        mul_no,
        sms_phone,
      } = orderData;

      const originalOrderData = await dispatch(
        asyncReadSpecificDataFetch({ table: 'order', id })
      )
        .unwrap()
        .then((res) => res[0]);

      const paymentStatus = originalOrderData.payment_status;
      const originalPaymentTerms = originalOrderData.payment_terms;
      const newPaymentTerms = payment_terms;

      if (paymentStatus === '1') {
        // 재고 조정
        const { addedInventoryDatas, abstractedInventoryDatas } =
          await changeInventoryBySales({
            originalSalesId: id,
            newSalesDatas: orderProductDatas,
            accountCode: account_code,
            adminCode: admin_code,
            originalPaymentTerms,
            newPaymentTerms,
          });
        alertNotification({ addedInventoryDatas, abstractedInventoryDatas });
      }

      // 주문서 수정
      await dispatch(
        asyncUpdateDataFetch({
          table: 'order',
          id,
          state: 1,
          admin_code,
          warehouse_code,
          account_code,
          account_name,
          account_phone,
          deal_type,
          payment_terms,
          order_date,
          delivery_date,
          memo,
          address: selectedAddress.address,
          post_code: selectedAddress.postCode,
          mul_no,
          sms_phone,
          address_id: addressIdx,
        })
      );
      // 주문-판매 (order_sale) 수정함수 => 일괄 삭제 후 재생성
      await dispatch(
        asyncDeleteDataFetch({
          table: 'order_sale',
          orderId: id,
        })
      );
      await orderSaleDatas.forEach((orderSaleData) => {
        const { sale_code, sale_name, sale_quantity } = orderSaleData;
        dispatch(
          asyncCreateDataFetch({
            table: 'order_sale',
            order_id: id,
            sale_code,
            sale_name,
            sale_quantity,
          })
        );
      });
      // 주문-출고 (order-product) 수정함수 => 일괄 삭제 후 재생성
      await dispatch(
        asyncDeleteDataFetch({
          table: 'order_product',
          orderId: id,
        })
      );
      await orderProductDatas.forEach((orderProductData) => {
        const {
          key,
          product_id,
          sale_code,
          id: idToBeExcluded,
          reg_date: regDateToBeExcluded,
          mod_date: modeDateToBeExcluded,
          del_date: delDateToBeExcluded,
          ...newOrderProductData
        } = orderProductData;
        dispatch(
          asyncCreateDataFetch({
            table: 'order_product',
            order_id: id,
            ...newOrderProductData,
          })
        );
      });

      // loading
      setLoading(false);

      // 성공 모달 띄우기
      openModal({
        type: 'result',
        modalInfo: {
          modalTitle: '주문/판매',
          resultStatus: 'success',
          resultTitle: '주문/판매 데이터를 수정했습니다.',
          resultSubTitle: '',
          buttonText: '확인',
        },
      });

      // 목록으로 넘어가기
      if (paymentStatus === '0') {
        history.push('/order');
      }
      if (paymentStatus === '1') {
        history.push('/sales-inquiry');
      }
      if (paymentStatus === '2') {
        history.push('/estimate');
      }
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  }, [
    dispatch,
    history,
    id,
    openModal,
    orderData,
    orderProductDatas,
    orderSaleDatas,
    handleValidation,
    addressIdx,
  ]);

  const handleDelete = useCallback(async () => {
    const confirmDelete = window.confirm(
      '정말 주문/판매 데이터를 삭제하시겠습니까?'
    );
    if (!confirmDelete) {
      return;
    }
    try {
      setLoading(true);

      // payment_status 받기
      const paymentStatus = await dispatch(
        asyncReadSpecificDataFetch({ table: 'order', id })
      )
        .unwrap()
        .then((res) => res[0].payment_status);

      if (paymentStatus === '1') {
        // 재고증가
        const addedInventoryDatas = await addInventoryBySales(id);
        alertNotification({
          addedInventoryDatas,
          abstractedInventoryDatas: null,
        });
      }

      // 주문서 (order) 삭제 함수
      await dispatch(asyncUpdateDataFetch({ table: 'order', id, state: 9 }));

      // loading
      setLoading(false);

      // 성공 모달 띄우기
      openModal({
        type: 'result',
        modalInfo: {
          modalTitle: '주문/판매',
          resultStatus: 'success',
          resultTitle: '주문/판매 데이터를 삭제했습니다.',
          resultSubTitle: '',
          buttonText: '확인',
        },
      });
      // 목록으로 넘어가기
      if (paymentStatus === '0') {
        history.push('/order');
      } else if (paymentStatus === '1') {
        history.push('/sales-inquiry');
      } else if (paymentStatus === '2') {
        history.push('/estimate');
      } else {
        history.push('/sales-inquiry');
      }
    } catch (error) {
      throw new Error(error);
    }
  }, [dispatch, id, openModal, history]);

  const subtractTrailing = () => {
    setOrderProductDatas((prev) =>
      prev.map((data) => {
        if (data.discounted <= 0) return data;
        const strNumber = data.discounted.toString();
        const isEndZero = strNumber.endsWith('0');
        if (!isEndZero) {
          const newDiscounted = data.discounted - 1;
          const newDiscountedVat = data.discounted_vat - 1;
          const newDiscount = data.discount + 1;
          return {
            ...data,
            discounted: newDiscounted,
            discounted_vat: newDiscountedVat,
            discount: newDiscount,
          };
        }
        return data;
      })
    );
  };

  return (
    <>
      <div
        style={{
          paddingBottom: '10px',
          fontSize: '16px',
          borderBottom: '1px solid #6d6d6d',
        }}
      >
        주문서 입력
      </div>
      <OrderForm
        pageType={pageType}
        orderItems={orderItems}
        orderData={orderData}
        setOrderData={setOrderData}
        recentOrderTrigger={(accountCode) => {
          orderSaleRef.current.handleRecentOrder(accountCode);
          orderProductRef.current.handleRecentOrder(accountCode);
        }}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
        setOrderProductDatas={setOrderProductDatas}
        setOrderSaleDatas={setOrderSaleDatas}
        addressIdx={addressIdx}
        setAddressIdx={setAddressIdx}
        orderProductDatas={orderProductDatas}
        setDcAccountProductDatas={setDcAccountProductDatas}
        orderId={id}
      />
      <div
        style={{
          paddingBottom: '10px',
          fontSize: '16px',
          borderBottom: '1px solid #6d6d6d',
          marginTop: '40px',
        }}
      >
        주문-판매 정보
      </div>
      <OrderSaleForm
        pageType={pageType}
        orderSaleItems={orderSaleItems}
        orderSaleDatas={orderSaleDatas}
        setOrderSaleDatas={setOrderSaleDatas}
        createDataTrigger={(newDatas) => {
          orderProductRef.current.handleCreate(newDatas);
        }}
        deleteDataTrigger={(newDatas) => {
          orderProductRef.current.handleDelete(newDatas);
        }}
        quantityDataTrigger={(newDatas) => {
          orderProductRef.current.handleQuantity(newDatas);
        }}
        ref={orderSaleRef}
        orderProductDatas={orderProductDatas}
        setOrderProductDatas={setOrderProductDatas}
      />
      <div
        style={{
          paddingBottom: '10px',
          fontSize: '16px',
          borderBottom: '1px solid #6d6d6d',
          marginTop: '40px',
        }}
      >
        주문-출고 정보
      </div>
      <OrderProductForm
        pageType={pageType}
        orderSaleDatas={orderSaleDatas}
        orderProductDatas={orderProductDatas}
        setOrderProductDatas={setOrderProductDatas}
        orderData={orderData}
        ref={orderProductRef}
        setSmsDone={setSmsDone}
        smsDone={smsDone}
        setOrderData={setOrderData}
        handleCreate={handleCreate}
        dcAccountProductDatas={dcAccountProductDatas}
        setDcAccountProductDatas={setDcAccountProductDatas}
      />
      <div
        style={{
          backgroundColor: '#fafafa',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '20px 15px',
          borderRadius: '8px',
          border: '1px solid rgb(240, 239, 239)',
          marginTop: '50px',
        }}
      >
        <div>
          <Button onClick={() => history.push('/order')}>
            <UnorderedListOutlined />
            목록
          </Button>
          <Button
            onClick={() => window.location.reload()}
            style={{ marginLeft: '5px' }}
          >
            <ReloadOutlined />
            취소
          </Button>
        </div>
        {pageType === 'create' &&
          !window.location.pathname.includes('estimateToOrder') && (
            <Button
              type="primary"
              onClick={() => handleCreate('order')}
              loading={loading}
            >
              <PlusOutlined />
              추가
            </Button>
          )}
        {window.location.pathname.includes('estimateToOrder') && (
          <Button
            onClick={() => handleCreate('order')}
            type="primary"
            loading={loading}
          >
            <SwapOutlined />
            전환
          </Button>
        )}
        {pageType === 'detail' && (
          <div>
            <Button
              type="primary"
              onClick={() => history.push(`/order/updateOrder/${id}`)}
              loading={loading}
            >
              <EditOutlined />
              수정
            </Button>
            <Button
              type="primary"
              onClick={handleDelete}
              style={{ marginLeft: '5px' }}
              danger
              loading={loading}
            >
              <DeleteOutlined />
              삭제
            </Button>
          </div>
        )}
        {pageType === 'update' && (
          <Button onClick={handleUpdate} type="primary" loading={loading}>
            <SaveOutlined />
            저장
          </Button>
        )}
      </div>
    </>
  );
};

export default React.memo(Event);

Event.propTypes = {
  pageType: PropTypes.string.isRequired,
  orderItems: PropTypes.instanceOf(Array).isRequired,
  orderSaleItems: PropTypes.instanceOf(Array).isRequired,
  datas: PropTypes.instanceOf(Object).isRequired,
};
