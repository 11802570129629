import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getInventoryProductSetupApi } from '../apis/inventoryApi';
import { asyncReadDataFetch } from '../redux/modules/shared/readData';

const useFilterProductSetup = (datas) => {
  const dispatch = useDispatch();
  const [filteredDatas, setFilteredDatas] = useState([]);
  useEffect(() => {
    const filterDatas = async () => {
      /*
      // 레거시 코드
      const fetchedProductSetup = await dispatch(
        asyncReadDataFetch({ table: 'inventory_product_setup' })
      ).unwrap();
      */
      const resData = await getInventoryProductSetupApi();
      const fetchedProductSetup = resData;
      const result = datas.filter((data) =>
        fetchedProductSetup.some((item) => item.product_id === data.id)
      );
      setFilteredDatas(result);
    };
    filterDatas();
  }, [datas]);
  return filteredDatas;
};

export default useFilterProductSetup;
