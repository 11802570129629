import { useDispatch } from 'react-redux';

import { asyncReadDataFetch } from '../redux/modules/shared/readData';
import { asyncReadMatchDataFetch } from '../redux/modules/shared/readMatchData';
import { asyncReadFilteredDataFetch } from '../redux/modules/shared/readFilteredData';
import { asyncReadMatchLteDataFetch } from '../redux/modules/shared/readMatchLteData';
import { asyncReadMatchBulkDataFetch } from '../redux/modules/shared/readMatchBulkData';
import {
  getChangedStockApi,
  getInventoryOrderSettingApi,
  getInventoryProductSetupApi,
  getSafetyStockApi,
  getStackQuantityApi,
} from '../apis/inventoryApi';

const useGetInventoryData = () => {
  const dispatch = useDispatch();

  // 각 item의 id만 뽑기
  const convertToIdArray = (arrayToConvert) => {
    return arrayToConvert.map((item) => item.value);
  };

  // 선택된 상품 가져오기
  const getSelectedProducts = async (productIds) => {
    const selecteProducts = await Promise.all(
      productIds.map(async (productId) => {
        const product = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'product',
            eqKey: 'id',
            eqValue: productId,
          })
        )
          .unwrap()
          .then((res) => res[0]);
        return product;
      })
    );
    return selecteProducts;
  };

  // 선택된 창고 가져오기
  const getSelectedWarehouses = async (warehouseIds) => {
    const selectedWarehouses = await Promise.all(
      warehouseIds.map(async (warehouseId) => {
        const warehouse = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'warehouse',
            eqKey: 'id',
            eqValue: warehouseId,
          })
        )
          .unwrap()
          .then((res) => res[0]);
        return warehouse;
      })
    );
    return selectedWarehouses;
  };

  // 취급 품목 id 배열 가져오기
  const getHandlingProductIds = async () => {
    const resData = await getInventoryProductSetupApi();
    const handlingProductIds = resData.map((item) => item.product_id);
    /*
    // 레거시 코드
    const handlingProductIds = await dispatch(
      asyncReadDataFetch({ table: 'inventory_product_setup' })
    )
      .unwrap()
      .then((res) => res.map((item) => item.product_id));
    */
    return handlingProductIds;
  };

  // 취급 품목 가져오기
  const getHandlingProducts = async (handlingProductIds) => {
    const handlingProducts = await Promise.all(
      handlingProductIds.map(async (id) => {
        const handlingProduct = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'product',
            eqKey: 'id',
            eqValue: id,
          })
        )
          .unwrap()
          .then((res) => res[0]);
        return handlingProduct;
      })
    );
    return handlingProducts;
  };

  // 판매, 창고로 이루어진 배열 생성
  const generateProductAndWarehouseArray = ({ products, warehouses }) => {
    const array = [];
    for (const product of products) {
      for (const warehouse of warehouses) {
        array.push({ product, warehouse });
      }
    }
    return array;
  };

  // 재고 테이블 데이터 형식에 맞게 포맷팅
  const formatProductAndWarehouseArray = (productAndWarehouseArray) => {
    const formattedProductAndWarehouseArray = [];
    for (const currentItem of productAndWarehouseArray) {
      const lastItemOfFormattedProductAndWarehouseArray =
        formattedProductAndWarehouseArray[
          formattedProductAndWarehouseArray.length - 1
        ];
      const isProductEqual =
        lastItemOfFormattedProductAndWarehouseArray?.product ===
        currentItem?.product;
      if (isProductEqual) {
        lastItemOfFormattedProductAndWarehouseArray.warehouse.push({
          ...currentItem.warehouse,
        });
      } else {
        formattedProductAndWarehouseArray.push({
          ...currentItem,
          warehouse: [
            {
              ...currentItem.warehouse,
            },
          ],
        });
      }
    }
    return formattedProductAndWarehouseArray;
  };

  // 재고 순번 설정 데이터 가져오기
  const getInventoryOrderSettings = async () => {
    /*
    // 레거시 코드
    const inventoryOrderSettings = await dispatch(
      asyncReadDataFetch({ table: 'inventory_order_setting' })
    ).unwrap();
    */
    const resData = await getInventoryOrderSettingApi();
    const inventoryOrderSettings = resData;

    return inventoryOrderSettings;
  };

  // 재고 순번 설정에 맞게 정렬
  const sortProductAndWarehouseArray = async (productAndWarehouseArray) => {
    const inventoryOrderSettings = await getInventoryOrderSettings();
    const productAndWarehouseArrayWithOrder = productAndWarehouseArray.map(
      (currentItem) => {
        let currentItemOrder;
        inventoryOrderSettings.forEach((orderItem) => {
          const isEqualProductId =
            orderItem.product_id === currentItem.product.id;
          if (isEqualProductId) {
            currentItemOrder = orderItem.order;
          }
        });
        return { ...currentItem, order: currentItemOrder };
      }
    );
    const sortedProductAndWarehouseArray =
      productAndWarehouseArrayWithOrder.sort((a, b) => a.order - b.order);
    return sortedProductAndWarehouseArray;
  };

  // 누적 재고 개수 가져오기
  const getStackQuantity = async ({
    productId,
    warehouseId,
    inventoryChangeDate,
  }) => {
    /*
    // 레거시 코드
    const stackQuantity = await dispatch(
      asyncReadMatchLteDataFetch({
        table: 'inventory',
        match: {
          warehouse_id: warehouseId,
          product_id: productId,
        },
        lteKey: 'inventory_change_date',
        lteValue: inventoryChangeDate,
        order: 'reg_date',
        limit: 1,
      })
    )
      .unwrap()
      .then((res) => {
        return res[0] ? res[0].stack_quantity : 0;
      });
    */

    const postData = {
      productId,
      warehouseId,
      inventoryChangeDate,
    };
    const resData = await getStackQuantityApi(postData);
    const stackQuantity = resData[0] ? resData[0].stack_quantity : 0;

    return stackQuantity;
  };

  // 배열에 누적 재고 개수 붙이기
  const attachStackQuantityToArray = async ({ array, inventoryChangeDate }) => {
    const attachedArray = await Promise.all(
      array.map(async (currentItem) => {
        const { product } = currentItem;
        const warehouses = currentItem.warehouse;
        const warehousesAttachedStackQuantity = await Promise.all(
          warehouses.map(async (warehouse) => {
            const stackQuantity = await getStackQuantity({
              productId: product.id,
              warehouseId: warehouse.id,
              inventoryChangeDate,
            });
            return { ...warehouse, stackQuantity };
          })
        );
        return { ...currentItem, warehouse: warehousesAttachedStackQuantity };
      })
    );
    return attachedArray;
  };

  // 안전 재고 가져오기
  const getSafetyStock = async ({ productId, warehouseId }) => {
    /*
    // 레거시 코드
    const safetyStock = await dispatch(
      asyncReadMatchDataFetch({
        table: 'inventory_safety_stock',
        match: {
          product_id: productId,
          warehouse_id: warehouseId,
        },
        order: 'id',
        limit: 1,
      })
    )
      .unwrap()
      .then((res) => (res[0] ? res[0].safety_stock : null));
    */

    const postData = {
      productId,
      warehouseId,
    };
    const resData = await getSafetyStockApi(postData);
    const safetyStock = resData[0] ? resData[0].safety_stock : null;

    return safetyStock;
  };

  // 배열에 안전재고 붙이기
  const attachSafetyStockToArray = async (array) => {
    const attachedArray = await Promise.all(
      array.map(async (currentItem) => {
        const { product } = currentItem;
        const warehouses = currentItem.warehouse;
        const warehousesAttachedSafetyStock = await Promise.all(
          warehouses.map(async (warehouse) => {
            const safetyStock = await getSafetyStock({
              productId: product.id,
              warehouseId: warehouse.id,
            });
            return { ...warehouse, safetyStock };
          })
        );
        return { ...currentItem, warehouse: warehousesAttachedSafetyStock };
      })
    );
    return attachedArray;
  };

  // 변동 재고 가져오기
  const getChangedStock = async ({
    productId,
    warehouseId,
    inventoryChangeDate,
  }) => {
    /*
    // 레거시 코드
    const fetchedStockDatas = await dispatch(
      asyncReadMatchBulkDataFetch({
        table: 'inventory',
        match: {
          product_id: productId,
          warehouse_id: warehouseId,
          inventory_change_date: inventoryChangeDate,
        },
        order: 'id',
      })
    ).unwrap();
    console.log(fetchedStockDatas);
    */

    const postData = {
      productId,
      warehouseId,
      inventoryChangeDate,
    };
    const resData = await getChangedStockApi(postData);
    const fetchedStockDatas = resData;

    const sortedStockDatas = fetchedStockDatas.sort((a, b) => a.id - b.id);
    // 증가
    const addedStockDatas = sortedStockDatas.filter(
      (data) => data.change_quantity >= 0
    );
    const addedStockTotal = addedStockDatas.reduce(
      (acc, cur) => acc + cur.change_quantity,
      0
    );
    // 감소
    const abstractedStockDatas = sortedStockDatas.filter(
      (data) => data.change_quantity < 0
    );
    const abstractedStockTotal = abstractedStockDatas.reduce(
      (acc, cur) => acc + cur.change_quantity,
      0
    );
    return {
      add: { total: addedStockTotal, datas: addedStockDatas },
      abstract: { total: abstractedStockTotal, datas: abstractedStockDatas },
    };
  };

  // 배열에 변동재고 붙이기
  const attachChangedStockToArray = async ({ array, inventoryChangeDate }) => {
    const attachedArray = await Promise.all(
      array.map(async (currentItem) => {
        const { product } = currentItem;
        const warehouses = currentItem.warehouse;
        const warehousesAttachedChangedStock = await Promise.all(
          warehouses.map(async (warehouse) => {
            const changedStock = await getChangedStock({
              productId: product.id,
              warehouseId: warehouse.id,
              inventoryChangeDate,
            });
            return { ...warehouse, changedStock };
          })
        );
        return { ...currentItem, warehouse: warehousesAttachedChangedStock };
      })
    );
    return attachedArray;
  };

  // 내부 배열 구조분해하기
  const spreadInnerArray = (array) => {
    const spreadedArray = [];
    for (const innerArray of array) {
      spreadedArray.push(...innerArray);
    }
    return spreadedArray;
  };

  // 주문 데이터 가져오기
  const getOrderProductDatas = async () => {
    const orderDatas = await dispatch(
      asyncReadFilteredDataFetch({
        table: 'order',
        eqKey: 'payment_status',
        eqValue: '0',
      })
    ).unwrap();
    const orderProductDatas = await Promise.all(
      orderDatas.map(async (orderData) => {
        const orderProductData = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'order_product',
            eqKey: 'order_id',
            eqValue: orderData.id,
          })
        ).unwrap();
        return orderProductData;
      })
    );
    return spreadInnerArray(orderProductDatas);
  };

  // 발주 데이터 가져오기
  const getPurchaseOrderProductDatas = async () => {
    const purchaseOrderDatas = await dispatch(
      asyncReadFilteredDataFetch({
        table: 'purchase_order',
        eqKey: 'status',
        eqValue: 0,
      })
    ).unwrap();
    const purchaseOrderProductDatas = await Promise.all(
      purchaseOrderDatas.map(async (purchaseOrderData) => {
        const warehouseId = purchaseOrderData.warehouse_id;
        const purchaseOrderProductData = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'purchase_order_product',
            eqKey: 'purchase_order_id',
            eqValue: purchaseOrderData.id,
          })
        )
          .unwrap()
          .then((res) => res.map((item) => ({ ...item, warehouseId })));
        return purchaseOrderProductData;
      })
    );
    return spreadInnerArray(purchaseOrderProductDatas);
  };

  // 미판매 가져오기
  const getUnSold = async ({
    productCode,
    warehouseCode,
    orderProductDatas,
  }) => {
    const filteredUnSoldData = orderProductDatas.filter(
      (item) =>
        item.product_code === productCode &&
        item.product_warehouse === warehouseCode
    )[0];
    const unSold = filteredUnSoldData ? filteredUnSoldData.product_quantity : 0;
    return unSold;
  };

  // 미입고 가져오기
  const getUnReceived = async ({
    productId,
    warehouseId,
    purchaseOrderProductDatas,
  }) => {
    const filteredUnReceivedData = purchaseOrderProductDatas.filter(
      (item) =>
        item.product_id === productId && item.warehouseId === warehouseId
    )[0];
    const unReceived = filteredUnReceivedData
      ? filteredUnReceivedData.product_quantity
      : 0;
    return unReceived;
  };

  // 배열에 미판매, 미입고 붙이기
  const attachUnSoldAndUnReceivedToArray = async (array) => {
    const orderProductDatas = await getOrderProductDatas();
    const purchaseOrderProductDatas = await getPurchaseOrderProductDatas();
    const attachedArray = await Promise.all(
      array.map(async (currentItem) => {
        const { product } = currentItem;
        const warehouses = currentItem.warehouse;
        const warehousesAttachedUnSoldAndUnReceived = await Promise.all(
          warehouses.map(async (warehouse) => {
            const unSold = await getUnSold({
              productCode: product.code,
              warehouseCode: warehouse.code,
              orderProductDatas,
            });
            const unReceived = await getUnReceived({
              productId: product.id,
              warehouseId: warehouse.id,
              purchaseOrderProductDatas,
            });
            return { ...warehouse, unSold, unReceived };
          })
        );
        return {
          ...currentItem,
          warehouse: warehousesAttachedUnSoldAndUnReceived,
        };
      })
    );
    return attachedArray;
  };

  // 데이터 가져오기 공통부분 처리함수 (추상화)
  const handleProcessInventoryDatas = async (filteringFormData) => {
    const isProductSelected = filteringFormData.product.length >= 1;
    let products;
    if (isProductSelected) {
      products = await getSelectedProducts(
        convertToIdArray(filteringFormData.product)
      );
    } else {
      const handlingProductIds = await getHandlingProductIds();
      products = await getHandlingProducts(handlingProductIds);
    }
    const warehouses = await getSelectedWarehouses(
      convertToIdArray(filteringFormData.warehouse)
    );
    const productAndWarehouseArray = generateProductAndWarehouseArray({
      products,
      warehouses,
    });
    const formattedProductAndWarehouseArray = formatProductAndWarehouseArray(
      productAndWarehouseArray
    );
    const sortedProductAndWarehouseArray = await sortProductAndWarehouseArray(
      formattedProductAndWarehouseArray
    );
    const inventoryDatas = await attachStackQuantityToArray({
      array: sortedProductAndWarehouseArray,
      inventoryChangeDate: filteringFormData.inventoryChangeDate,
    });
    return inventoryDatas;
  };

  // 기본 재고현황 데이터 가져오기
  const getBasicInventoryDatas = async (filteringFormData) => {
    const inventoryDatas = await handleProcessInventoryDatas(filteringFormData);
    return inventoryDatas;
  };

  // 재고현황 + 안전재고 데이터 가져오기
  const getInventoryDatasWithSafetyStock = async (filteringFormData) => {
    const inventoryDatas = await handleProcessInventoryDatas(filteringFormData);
    const inventoryDatasWithSafetyStock = await attachSafetyStockToArray(
      inventoryDatas
    );
    return inventoryDatasWithSafetyStock;
  };

  // 재고현황 + 변동재고 데이터 가져오기
  const getInventoryDatasWithChangedStock = async (filteringFormData) => {
    const inventoryDatas = await handleProcessInventoryDatas(filteringFormData);
    const inventoryDatasWithChangedStock = await attachChangedStockToArray({
      array: inventoryDatas,
      inventoryChangeDate: filteringFormData.inventoryChangeDate,
    });
    return inventoryDatasWithChangedStock;
  };

  // 재고현황 + 미판매/미입고 데이터 가져오기
  const getInventoryDatasWithUnSoldAndUnReceived = async (
    filteringFormData
  ) => {
    const inventoryDatas = await handleProcessInventoryDatas(filteringFormData);
    const inventoryDatasWithUnSoldAndUnReceived =
      await attachUnSoldAndUnReceivedToArray(inventoryDatas);
    return inventoryDatasWithUnSoldAndUnReceived;
  };

  return {
    getBasicInventoryDatas,
    getInventoryDatasWithUnSoldAndUnReceived,
    getInventoryDatasWithSafetyStock,
    getInventoryDatasWithChangedStock,
  };
};

export default useGetInventoryData;
