import { Button, Card, Input, Modal, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  ArrowRightOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import uuid from 'react-uuid';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import { asyncReadMatchDataFetch } from '../../redux/modules/shared/readMatchData';
import { asyncUpdateDataFetch } from '../../redux/modules/shared/updateData';
import { asyncCreateDataFetch } from '../../redux/modules/shared/createData';
import { asyncDeleteDataFetch } from '../../redux/modules/shared/deleteData';
import useFilterProductSetup from '../../hook/useFilterProductSetup';
import {
  createInventorySafetyStockApi,
  deleteInventorySafetyStockApi,
  getSafetyStockApi,
  updateInventorySafetyStockApi,
} from '../../apis/inventoryApi';

const SafetyStockModal = (props) => {
  const { setIsOpenSafetyStockModal } = props;
  const dispatch = useDispatch();
  const [productDatas, setProductDatas] = useState([]);
  const [warehouseDatas, setWarehouseDatas] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({
    id: null,
    name: null,
  });
  const [selectedWarehouse, setSelectedWarehouse] = useState({
    id: null,
    name: null,
  });
  const [isDisableInput, setIsDisableInput] = useState(true);
  const [isDisableAddButton, setIsDisableAddButton] = useState(true);
  const [isDisableDeleteButton, setIsDisableDeleteButton] = useState(true);
  const [savedSafetyStock, setSavedSafetyStock] = useState({
    id: null,
    safetyStock: null,
  });
  const [safetyStockValue, setSafetyStockValue] = useState(null);
  const [safetyStockList, setSafetyStockList] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    const getDatas = async () => {
      try {
        const fetchedProducts = await dispatch(
          asyncReadDataFetch({ table: 'product' })
        ).unwrap();
        const fetchedWarehouses = await dispatch(
          asyncReadDataFetch({ table: 'warehouse' })
        ).unwrap();
        setProductDatas(fetchedProducts);
        setWarehouseDatas(fetchedWarehouses);
      } catch (err) {
        throw new Error(err);
      }
    };
    getDatas();
  }, []);

  useEffect(() => {
    const isSelectedBoth = selectedProduct.id && selectedWarehouse.id;
    if (!isSelectedBoth) return;
    const getSavedSafetyStock = async () => {
      try {
        /*
        // 레거시 코드
        const fetchedSavedSafetyStock = await dispatch(
          asyncReadMatchDataFetch({
            table: 'inventory_safety_stock',
            match: {
              warehouse_id: selectedWarehouse.id,
              product_id: selectedProduct.id,
            },
            order: 'id',
            limit: 1,
          })
        ).unwrap();
        */
        // 기존 안전재고 불러오기
        const postData = {
          warehouseId: selectedWarehouse.id,
          productId: selectedProduct.id,
        };
        const resData = await getSafetyStockApi(postData);
        const fetchedSavedSafetyStock = resData;
        if (fetchedSavedSafetyStock[0]) {
          setSavedSafetyStock({
            id: fetchedSavedSafetyStock[0].id,
            safetyStock: fetchedSavedSafetyStock[0].safety_stock,
          });
          setIsDisableDeleteButton(false);
        } else {
          setSavedSafetyStock({ id: null, safetyStock: null });
          setIsDisableDeleteButton(true);
        }
        // 인풋 및 저장 사용가능 처리
        setIsDisableInput(false);
      } catch (error) {
        throw new Error(error);
      }
    };
    getSavedSafetyStock();
  }, [selectedProduct, selectedWarehouse]);

  useEffect(() => {
    if (safetyStockValue > 0) {
      setIsDisableAddButton(false);
    } else {
      setIsDisableAddButton(true);
    }
  }, [safetyStockValue]);

  const initializeStates = () => {
    setSelectedProduct({
      id: null,
      name: null,
    });
    setSelectedWarehouse({
      id: null,
      name: null,
    });
    setSavedSafetyStock({
      id: null,
      safetyStock: null,
    });
    setIsDisableInput(true);
    setIsDisableAddButton(true);
    setIsDisableDeleteButton(true);
    setSafetyStockValue(null);
  };

  const checkDuplication = (productId, warehouseId) => {
    return safetyStockList.some(
      (item) =>
        item.product.id === productId && item.warehouse.id === warehouseId
    );
  };

  const handleAdd = async () => {
    const isSelectedBoth = selectedProduct.id && selectedWarehouse.id;
    if (!isSelectedBoth) return;
    const savedSafetyStockId = savedSafetyStock.id || null;
    const savedSafetyStockValue = savedSafetyStock.safetyStock || null;
    const newSafetyStockValue = Number(safetyStockValue);
    const product = {
      id: selectedProduct.id,
      name: selectedProduct.name,
    };
    const warehouse = {
      id: selectedWarehouse.id,
      name: selectedWarehouse.name,
    };
    const isDupWithListAndSelected = checkDuplication(product.id, warehouse.id);
    if (isDupWithListAndSelected) {
      alert('해당 품목과 창고의 안전재고는 이미 입력되어 있습니다.');
      return;
    }
    const isSameStockValueWithSavedAndNew =
      savedSafetyStockValue === newSafetyStockValue;
    if (isSameStockValueWithSavedAndNew) {
      alert('기존 안전재고와 같은 값을 입력할 수 없습니다.');
      return;
    }
    setSafetyStockList((prev) => [
      ...prev,
      {
        id: uuid(),
        savedSafetyStockId,
        savedSafetyStockValue,
        newSafetyStockValue,
        product,
        warehouse,
      },
    ]);
    initializeStates();
  };

  const handleRemove = (id) => {
    setSafetyStockList((prev) => prev.filter((item) => item.id !== id));
  };

  const handleDelete = async () => {
    const hasSavedSaftyStock = savedSafetyStock.id;
    if (!hasSavedSaftyStock) return;
    const confirmDelete = window.confirm(
      '정말 기존 안전재고 데이터를 삭제하시겠습니까?'
    );
    if (!confirmDelete) return;
    try {
      /*
      // 레거시 코드
      await dispatch(
        asyncDeleteDataFetch({
          table: 'inventory_safety_stock',
          id: savedSafetyStock.id,
        })
      );
      */
      const postData = { id: savedSafetyStock.id };
      await deleteInventorySafetyStockApi(postData);
      alert('안전재고가 삭제되었습니다. 적용을 위해 새로고침 해주세요.');
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleSave = async () => {
    if (safetyStockList.length <= 0) {
      alert('안전재고를 입력해주세요.');
      return;
    }
    try {
      setSaveLoading(true);
      for (const safetyStockItem of safetyStockList) {
        const isSavedSafetyStockItem = !!safetyStockItem.savedSafetyStockId;
        if (isSavedSafetyStockItem) {
          /*
          // 레거시 코드
          await dispatch(
            asyncUpdateDataFetch({
              table: 'inventory_safety_stock',
              id: safetyStockItem.savedSafetyStockId,
              safety_stock: safetyStockItem.newSafetyStockValue,
            })
          );
          */
          const postData = {
            id: safetyStockItem.savedSafetyStockId,
            safetyStock: safetyStockItem.newSafetyStockValue,
          };
          console.log(postData);
          await updateInventorySafetyStockApi(postData);
        } else {
          /*
          레거시 코드
          await dispatch(
            asyncCreateDataFetch({
              table: 'inventory_safety_stock',
              product_id: safetyStockItem.product.id,
              warehouse_id: safetyStockItem.warehouse.id,
              safety_stock: safetyStockItem.newSafetyStockValue,
            })
          );
          */
          const postData = {
            productId: safetyStockItem.product.id,
            warehouseId: safetyStockItem.warehouse.id,
            safetyStock: safetyStockItem.newSafetyStockValue,
          };
          await createInventorySafetyStockApi(postData);
        }
      }
      setSaveLoading(false);
      setIsOpenSafetyStockModal(false);
      alert('안전재고 설정이 완료되었습니다. 적용을 위해 새로고침 해주세요.');
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <Modal
      width={700}
      centered
      open
      title="안전재고"
      footer={[
        <Button onClick={() => setIsOpenSafetyStockModal(false)}>닫기</Button>,
        <Button type="primary" onClick={handleSave} loading={saveLoading}>
          <SaveOutlined />
          저장
        </Button>,
      ]}
      onCancel={() => setIsOpenSafetyStockModal(false)}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <ContainerDiv>
          <div style={{ fontWeight: '700' }}>품목선택</div>
          <BackgroundDiv>
            {useFilterProductSetup(productDatas).map((item) => (
              <Button
                type={item.id === selectedProduct.id ? 'primary' : 'default'}
                onClick={() =>
                  setSelectedProduct({ id: item.id, name: item.name })
                }
              >
                {item.name}
              </Button>
            ))}
          </BackgroundDiv>
        </ContainerDiv>
        <ContainerDiv>
          <div style={{ fontWeight: '700' }}>창고선택</div>
          <BackgroundDiv>
            {warehouseDatas.map((item) => (
              <Button
                type={item.id === selectedWarehouse.id ? 'primary' : 'default'}
                onClick={() =>
                  setSelectedWarehouse({ id: item.id, name: item.name })
                }
              >
                {item.name}
              </Button>
            ))}
          </BackgroundDiv>
        </ContainerDiv>
        <ContainerDiv>
          <div
            style={{
              backgroundColor: '#f9f9f9',
              borderRadius: '20px',
              padding: '15px',
              height: '100%',
              textAlign: 'start',
            }}
          >
            <div>
              <div>선택된 품목</div>
              <Input
                value={selectedProduct.name}
                placeholder="품목을 선택해주세요."
                disabled
              />
            </div>
            <div style={{ marginTop: '10px' }}>
              <div>선택된 창고</div>
              <Input
                value={selectedWarehouse.name}
                placeholder="창고를 선택해주세요."
                disabled
              />
            </div>
            <div style={{ marginTop: '10px' }}>
              <div>기존 안전재고</div>
              <Input
                value={
                  selectedProduct.id && selectedWarehouse.id
                    ? savedSafetyStock.safetyStock
                      ? savedSafetyStock.safetyStock
                      : '미설정'
                    : null
                }
                placeholder="기존 안전재고"
                disabled
                style={{ paddingRight: '3px' }}
                suffix={
                  <Button
                    type="primary"
                    danger
                    disabled={isDisableDeleteButton}
                    onClick={handleDelete}
                    size="small"
                  >
                    삭제
                  </Button>
                }
              />
            </div>
            <div
              style={{
                border: '0.5px solid lightgray',
                width: '100%',
                margin: '30px 0',
              }}
            />
            <div>
              <div>안전재고 설정</div>
              <Input
                type="number"
                disabled={isDisableInput}
                value={safetyStockValue}
                onChange={(e) => setSafetyStockValue(e.target.value)}
                placeholder="안전재고를 입력해주세요."
                style={{ paddingRight: '3px' }}
                suffix={
                  <Button
                    type="primary"
                    disabled={isDisableAddButton}
                    onClick={handleAdd}
                    size="small"
                  >
                    <PlusCircleOutlined />
                  </Button>
                }
              />
              <div
                style={{
                  padding: '10px',
                  borderRadius: '10px',
                  backgroundColor: 'white',
                  height: '390px',
                  overflow: 'auto',
                  marginTop: '5px',
                }}
              >
                {safetyStockList.map((item) => (
                  <div
                    style={{
                      padding: '5px',
                      borderRadius: '5px',
                      backgroundColor: '#f9f9f9',
                      marginBottom: '3px',
                      textAlign: 'center',
                      border: '1px solid #efefef',
                    }}
                  >
                    <div style={{ fontSize: '10px', color: 'gray' }}>
                      {item.warehouse.name}
                    </div>
                    <div style={{ fontSize: '12px' }}>{item.product.name}</div>
                    <div style={{ marginTop: '5px' }}>
                      <span>
                        <Tag>{item.savedSafetyStockValue || '미설정'}</Tag>
                      </span>
                      <span
                        style={{
                          marginRight: '5px',
                          fontSize: '10px',
                        }}
                      >
                        <ArrowRightOutlined />
                      </span>
                      <span>
                        <Tag>{item.newSafetyStockValue}</Tag>
                      </span>
                    </div>
                    <Button
                      style={{ width: '100%', marginTop: '5px' }}
                      type="primary"
                      danger
                      size="small"
                      onClick={() => handleRemove(item.id)}
                    >
                      <MinusCircleOutlined />
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </ContainerDiv>
      </div>
    </Modal>
  );
};

export default SafetyStockModal;

SafetyStockModal.propTypes = {
  setIsOpenSafetyStockModal: PropTypes.func.isRequired,
};

const ContainerDiv = styled.div`
  width: 33%;
  text-align: center;
`;
const BackgroundDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 700px;
  overflow: auto;
  text-align: center;
  margin-top: 5px;
`;
