import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Modal, Result, Table, Tag } from 'antd';
import { LockFilled, SendOutlined, UnlockOutlined } from '@ant-design/icons';
import styled, { css, keyframes } from 'styled-components';
import sms from '../../sms';

const OfferComponent = (props) => {
  const { offerDatas } = props;
  const { sendProposalAllinda } = sms();

  const column = [
    { title: '거래처', dataIndex: 'account_name', width: '30%' },
    { title: '상품', dataIndex: 'goods', width: '30%' },
    { title: '판매일', dataIndex: 'last_sales_date', width: '30%' },
    {
      title: '수량',
      dataIndex: 'last_sales_amount',
      width: '10%',
      render: (value) => <div>{value}EA</div>,
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [isPwModalOpen, setIsPwModalOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [proposeResult, setProposeResult] = useState([]);
  const [isNotCorrectPw, setIsNotCorrectPw] = useState(false);
  const [proposeLoading, setProposeLoading] = useState(false);

  const openPwModal = () => {
    if (selectedRowKeys.length === 0) {
      alert('거래처를 선택해주세요.');
      return;
    }
    setIsPwModalOpen(true);
  };

  const reqProposalAllinda = async () => {
    await setProposeLoading(true);
    // goods, phoneNumber로 구성된 배열 만들기
    const goodsAndPhoneNum = [];
    for (const offerData of offerDatas) {
      const { datas } = offerData;
      for (const data of datas) {
        const { key } = data;
        for (const selectedRowKey of selectedRowKeys) {
          if (key === selectedRowKey) {
            const { goods } = data;
            const phoneNumber = data.account_contact_information1;
            goodsAndPhoneNum.push({ goods, phoneNumber });
          }
        }
      }
    }
    // sendProposalAllinda함수로 보내기
    const values = {
      password,
      goodsAndPhoneNum,
    };
    const resProposalAllinda = await sendProposalAllinda(values);
    // sendProposalAllinda함수의 response에 따라 UI처리
    // 비밀번호 틀림
    if (
      resProposalAllinda.isSuccess === false &&
      resProposalAllinda.message === 'wrong-password'
    ) {
      setIsNotCorrectPw(true);
      setTimeout(() => {
        setIsNotCorrectPw(false);
      }, [1000]);
      setPassword('');
      setProposeLoading(false);
      return;
    }
    await setProposeResult(resProposalAllinda);
    await setProposeLoading(false);
    setTimeout(() => {
      setIsPwModalOpen(false);
      setProposeResult([]);
      setPassword('');
    }, [3000]);
  };

  /*
  const returnResultTitile = (datas) => {
    let successCount = 0;
    let failCount = 0;
    for (const data of datas) {
      if (data.isSuccess) {
        successCount += 1;
      } else {
        failCount += 1;
      }
    }
    if (failCount > 0) {
      alert(
        `${failCount}건의 주문제안 문자를 전송하는데 실패했습니다. 개발팀에 문의해주세요.`
      );
    }
    return `${successCount}건의 주문제안 문자를 전송했습니다.`;
  };
  */

  return (
    <div>
      {offerDatas.map((item, index) => (
        <div style={{ marginBottom: '5px', position: 'relative' }} key={index}>
          <div style={{ display: 'flex', marginBottom: '5px' }}>
            <div
              style={{
                border: '0.5px solid #efefef',
                padding: '10px',
                fontSize: '15px',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#fafafa',
              }}
            >
              <Button size="small" type="link" onClick={openPwModal}>
                <SendOutlined />
              </Button>
            </div>
            <div
              style={{
                border: '0.5px solid #efefef',
                padding: '15px',
                fontSize: '15px',
                borderRadius: '10px',
                marginLeft: '5px',
                backgroundColor: '#fafafa',
              }}
            >
              {item.propose_day.slice(0, 4)}년 {item.propose_day.slice(5, 7)}월{' '}
              {item.propose_day.slice(8)}일
            </div>
          </div>
          <Table
            className="no-border-radius-table"
            style={{ borderRadius: '10px' }}
            columns={column}
            dataSource={item.datas}
            pagination={false}
            rowKey="key"
            rowSelection={rowSelection}
          />
        </div>
      ))}
      {isPwModalOpen && (
        <Modal
          open
          centered
          onCancel={() => {
            setIsPwModalOpen(false);
            setPassword('');
          }}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '30px',
              flexDirection: 'column',
            }}
          >
            {proposeResult.length === 0 && (
              <>
                <Container shake={isNotCorrectPw}>
                  <LockFilled
                    style={{
                      color: isNotCorrectPw ? '#f5222d' : '#1677ff',
                      fontSize: '30px',
                    }}
                  />
                </Container>
                <div
                  style={{
                    marginTop: '30px',
                    fontWeight: '500',
                    fontSize: '16px',
                    color: isNotCorrectPw ? '#f5222d' : 'black',
                  }}
                >
                  {isNotCorrectPw
                    ? '패스워드를 다시 입력해주세요.'
                    : '비밀번호를 입력한 후 전송을 클릭해주세요.'}
                </div>
                <Input
                  bordered={false}
                  style={{
                    borderRadius: '0px',
                    borderBottom: '2px solid lightgray',
                    width: '50%',
                    marginTop: '20px',
                    textAlign: 'center',
                    fontSize: '15px',
                  }}
                  placeholder="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  style={{ marginTop: '30px' }}
                  type="primary"
                  danger={isNotCorrectPw}
                  onClick={reqProposalAllinda}
                  loading={proposeLoading}
                >
                  <SendOutlined /> 전송
                </Button>
              </>
            )}

            {proposeResult.length > 0 && (
              <Result
                icon={<UnlockOutlined />}
                status="success"
                title="주문제안 문자가 발송되었습니다."
                // subTitle={returnResultTitile(proposeResult)}
              />
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default OfferComponent;

OfferComponent.propTypes = {
  offerDatas: PropTypes.instanceOf(Array).isRequired,
};

const shakeAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-5px);
  }
  20% {
    transform: translateX(5px);
  }
  30% {
    transform: translateX(-5px);
  }
  40% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  60% {
    transform: translateX(5px);
  }
  70% {
    transform: translateX(-5px);
  }
  80% {
    transform: translateX(5px);
  }
  90% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
`;
const shakeAnimationCSS = css`
  animation: ${shakeAnimation} 1s linear infinite;
`;
const Container = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #e6f4ff;
  display: flex;
  justify-content: center;
  ${(props) => props.shake && shakeAnimationCSS}
  background-color: ${(props) => (props.shake ? '#fff1f0' : '#e6f7ff')}
`;
