import { Button, DatePicker } from 'antd';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { asyncCreateDataFetch } from '../../redux/modules/shared/createData';
import { asyncReadLikeDataFetch } from '../../redux/modules/shared/readLikeData';
import { asyncUpdateDataFetch } from '../../redux/modules/shared/updateData';
import MonthlySalesTargetCell from './MonthlySalesTargetCell';
import { DARK_MODE_BACKGROUND, LIGHT_MODE_BACKGROUND } from '../../constants';
import { getSalesTargetsByYearApi } from '../../apis/dataViewApi';

const MonthlySalesTarget = (props) => {
  const { darkMode, children } = props;
  const dispatch = useDispatch();

  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [salesTargetDatas, setSalesTargetDatas] = useState([]);

  const getNewSalesTarget = () => {
    const newSalesTarget = window.prompt(
      '매출 목표치를 입력해주세요. *숫자만 입력'
    );
    return newSalesTarget;
  };

  const validateIsOnlyNumber = (value) => /^\d+$/.test(value);

  // 매출 목표치 데이터 생성
  const createSalesTarget = async (postData) => {
    try {
      const newSalesTargetData = await dispatch(asyncCreateDataFetch(postData))
        .unwrap()
        .then((res) => {
          const { id, date, sales_target } = res[0];
          return { id, date, salesTarget: sales_target };
        });
      setSalesTargetDatas((prev) => [...prev, newSalesTargetData]);
    } catch (error) {
      console.log(error);
    }
  };

  // 매출 목표치 데이터 업데이트
  const updateSalesTarget = (postData) => {
    try {
      dispatch(asyncUpdateDataFetch(postData));
      const newSalesTargetData = {
        id: postData.id,
        salesTarget: postData.sales_target,
        date: postData.date,
      };
      setSalesTargetDatas((prev) =>
        prev.map((originalSalesTargetData) => {
          console.log(newSalesTargetData, originalSalesTargetData);
          const isEqualId =
            newSalesTargetData.id === originalSalesTargetData.id;
          if (isEqualId) {
            return newSalesTargetData;
          } else {
            return originalSalesTargetData;
          }
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  // 입력된 매출 목표치 받아 출력하기
  const handleSalesTarget = ({ id, date }) => {
    const newSalesTarget = getNewSalesTarget();
    if (!newSalesTarget) {
      return;
    }
    if (!validateIsOnlyNumber(newSalesTarget)) {
      window.alert('숫자만 입력할 수 있습니다.');
      return;
    }
    const numberedSalesTarget = Number(newSalesTarget);
    const isUpdateSalesTarget = typeof id === 'number';
    const postData = {
      table: 'sales_target',
      sales_target: numberedSalesTarget,
      date,
    };
    if (isUpdateSalesTarget) {
      updateSalesTarget(Object.assign(postData, { id }));
    } else {
      createSalesTarget(postData);
    }
  };

  const getYear = (dateValue) => {
    return dateValue.format('YYYY');
  };

  const getDate = (dateValue) => {
    return dateValue.format('YYYY-MM');
  };

  const getDateWord = (dateValue) => {
    return dateValue.format('M월');
  };

  const getSalesTargetDatas = async (year) => {
    const postData = { year };
    const fetchedSalesTargetDatas = await getSalesTargetsByYearApi(postData);
    const formattedSalesTargetDatas = fetchedSalesTargetDatas.map((item) => ({
      id: item.id,
      date: item.date,
      salesTarget: Math.round(item.sales_target / 10000),
    }));
    setSalesTargetDatas(formattedSalesTargetDatas);
  };

  const handlePanelChange = (dateValue) => {
    const currentYear = getYear(dateValue);
    const isChangedYear = currentYear !== selectedYear;
    if (isChangedYear) {
      getSalesTargetDatas(currentYear);
      setSelectedYear(currentYear);
    }
  };

  useEffect(() => {
    const currentYear = getYear(dayjs());
    getSalesTargetDatas(currentYear);
    setSelectedYear(currentYear);
  }, []);

  const getCurrentSalesTargetData = ({
    currentDate,
    currentSalesTargetDatas,
  }) => {
    const filteredSalesTargetData = currentSalesTargetDatas.filter(
      (currentSalesTargetData) => currentSalesTargetData.date === currentDate
    )[0];
    return filteredSalesTargetData;
  };

  const renderCurrentCell = ({ dateValue }) => {
    const currentDate = getDate(dateValue);
    const currentDateWord = getDateWord(dateValue);
    const currentSalesTargetData = getCurrentSalesTargetData({
      currentDate,
      currentSalesTargetDatas: salesTargetDatas,
    });

    let id = null;
    let salesTarget = null;
    const isExistSalesTargetData = !!currentSalesTargetData;
    if (isExistSalesTargetData) {
      id = currentSalesTargetData.id;
      salesTarget = currentSalesTargetData.salesTarget;
    }

    return (
      <MonthlySalesTargetCell
        dateWord={currentDateWord}
        date={currentDate}
        id={id}
        salesTarget={salesTarget}
        handleSalesTarget={handleSalesTarget}
      />
    );
  };

  const handleCancelDatePicker = () => {
    setIsOpenDatePicker(false);
  };

  const renderCancelFooter = () => {
    return (
      <div style={{ width: '100%', textAlign: 'center', padding: '5px' }}>
        <Button
          type="link"
          onClick={handleCancelDatePicker}
          style={{ fontWeight: '600' }}
        >
          닫기
        </Button>
      </div>
    );
  };

  return (
    <div>
      {!isOpenDatePicker && (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => setIsOpenDatePicker(true)}
        >
          {children}
        </div>
      )}
      {isOpenDatePicker && (
        <div style={{ position: 'relative' }}>
          <DatePicker
            style={{
              backgroundColor: darkMode
                ? DARK_MODE_BACKGROUND
                : LIGHT_MODE_BACKGROUND,
              border: darkMode && 'none',
            }}
            picker="month"
            size="small"
            open
            onPanelChange={(dateValue) => handlePanelChange(dateValue)}
            monthCellRender={(dateValue) => renderCurrentCell({ dateValue })}
            renderExtraFooter={renderCancelFooter}
          />
        </div>
      )}
    </div>
  );
};

export default MonthlySalesTarget;

MonthlySalesTarget.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
