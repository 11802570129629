import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import uuid from 'react-uuid';

import { asyncReadDataFetch } from '../redux/modules/shared/readData';

const table = 'monthly_headcount';

const useHeadcount = (isSelected, range) => {
  const dispatch = useDispatch();

  const [headcount, setHeadcount] = useState([]);

  const fetchData = useCallback((table) => {
    return dispatch(asyncReadDataFetch({ table })).unwrap();
  }, []);

  const getMissingData = useCallback((start, end) => {
    let current = start;
    let target = end;
    const missingData = [];
    while (current < target) {
      const id = uuid();
      missingData.push({ id, year_month: current, headcount: 1 });
      current = dayjs(current).add(1, 'month').format('YYYY-MM');
    }
    return missingData;
  });

  const handleHeadcount = useCallback(
    async (isSelected, range, table) => {
      const fetchedData = await fetchData(table);

      if (!fetchData || fetchData.length === 0) {
        setHeadcount([]);
        return;
      }

      fetchedData.sort(
        (a, b) => new Date(a.year_month) - new Date(b.year_month)
      );

      const firstYearMonthOfData = fetchedData[0].year_month;
      const startYearMonthOfRange = range.start;

      const isExistMissingYearMonthBetweenRangeAndData =
        startYearMonthOfRange < firstYearMonthOfData;

      if (isExistMissingYearMonthBetweenRangeAndData) {
        const missingData = getMissingData(
          startYearMonthOfRange,
          firstYearMonthOfData
        );
        fetchedData.unshift(...missingData);
      }

      if (isSelected) {
        const filteredData = fetchedData.filter(
          (item) =>
            new Date(item.year_month) >= new Date(range.start) &&
            new Date(item.year_month) <= new Date(range.end)
        );
        setHeadcount(filteredData);
        return;
      }

      setHeadcount(fetchedData);
    },
    [isSelected, range, table]
  );

  useEffect(() => {
    handleHeadcount(isSelected, range, table);
  }, [isSelected, range, table]);

  return headcount;
};

export default useHeadcount;
