import axiosInstance from '../axios';

const getAccountListByIds = async (postData) => {
  const url = 'api/account/list';
  const response = await axiosInstance.post(url, postData);
  return response.data;
};

const foo = () => {};

export { getAccountListByIds, foo };
