/*
import axios from 'axios';
import dayjs from 'dayjs';

const sms = () => {
  // 알린다 api
  const sendAllinda = async (values) => {
    const url = 'https://intosharp.pythonanywhere.com/send_sms';
    try {
      await axios({
        method: 'POST',
        url,
        data: values,
      });
      alert('SMS를 전송하는데 성공했습니다.');
    } catch (err) {
      alert('SMS를 전송하는데 실패했습니다.');
    }
  };

  // 페이앱 api
  const sendPayapp = async (values) => {
    const url = 'https://intosharp.pythonanywhere.com/payapp';
    try {
      const res = await axios({
        method: 'POST',
        url,
        data: values,
      });
      const { result_data } = res.data;
      const payUrl = decodeURIComponent(result_data.match(/payurl=([^&]*)/)[1]);
      const payCode = new URLSearchParams(result_data).get('mul_no');
      return { payUrl, payCode };
    } catch (err) {
      console.log(err);
      alert('결제링크를 전송하는데 실패했습니다.');
    }
  };

  // 등록된 사용자 체크
  const isCheckMember = (passwordNumber) => {
    if (
      passwordNumber !== '2191' &&
      passwordNumber !== '9662' &&
      passwordNumber !== '3790' &&
      passwordNumber !== '6318'
    ) {
      return false;
    }
    return true;
  };

  // 메시지 생성
  const getSendMessage = (data) => {
    let itemTitle = data.goods;

    if (itemTitle.match(/[A-Z]/)) {
      itemTitle = itemTitle.replace(/[A-Z]/g, '');
    } else if (itemTitle.includes('일회용식판')) {
      itemTitle = '일회용식판';
    }

    const normalMessage = `
안녕하세요, 한통도시락입니다.
오늘은 인공지능이 계산한 "${itemTitle}"의 예상 구매날짜 입니다.
재고를 확인 후 구매는 아래 링크를 이용해주세요.
https://smartstore.naver.com/hantongbox

(주)한통을 이용해 주셔서 감사합니다.
`;
    const specialMessage = `
안녕하세요, 한통도시락입니다.
오늘은 인공지능이 계산한 "${itemTitle}"의 예상 구매날짜 입니다.
재고를 확인 후 구매는 아래 링크를 이용해주세요.
https://smartstore.naver.com/hantongbox

기상악화로 배송지연이 발생할 수 있으니 서둘러 주문을 부탁드립니다.
  
(주)한통을 이용해 주셔서 감사합니다.
`;

    const today = dayjs();
    const year = today.year();

    const startDate = dayjs(`${year}-04-01`);
    const endDate = dayjs(`${year}-11-16`);

    const isNormalDate =
      (today.isAfter(startDate) && today.isBefore(endDate)) ||
      today.isSame(startDate);

    return isNormalDate ? normalMessage : specialMessage;
  };

  const reqestAllinda = async (phoneNumber, message, password) => {
    const url = `https://intosharp.pythonanywhere.com/send_sms_userid/${password}`;
    const data = {
      recipients: [{ phoneNumber }],
      contents: message,
    };
    try {
      const res = await axios({
        method: 'POST',
        url,
        data,
      });
      return res;
    } catch (err) {
      return err;
    }
  };

  // 주문제안 api
  const sendProposalAllinda = async (values) => {
    const { password, goodsAndPhoneNum } = values;
    // password 체크
    const isCorrectPw = await isCheckMember(password);
    if (!isCorrectPw) {
      return { isSuccess: false, message: 'wrong-password' };
    }
    const resultData = [];
    for (const data of goodsAndPhoneNum) {
      // 메시지 받기
      const message = await getSendMessage(data);
      const { phoneNumber, goods } = data;
      const phoneNumberWithoutHyphen = phoneNumber.replace(/-/g, '');
      if (phoneNumberWithoutHyphen.length <= 0 || !phoneNumberWithoutHyphen) {
        return;
      }
      const responseAllinda = await reqestAllinda(
        phoneNumberWithoutHyphen,
        message,
        password
      );
      // client로 보내줄 값 생성
      if (responseAllinda.status === 200) {
        resultData.push({
          isSuccess: true,
          message: 'success',
          goods,
          phoneNumber,
        });
      } else {
        resultData.push({
          isSuccess: false,
          message: 'fail',
          goods,
          phoneNumber,
        });
      }
    }
    return resultData;
  };

  return { sendAllinda, sendPayapp, sendProposalAllinda };
};

export default sms;
*/

import dayjs from 'dayjs';
import isOwnServerApi from './thirdPartyApiConfig';
import {
  getParamUrl,
  getResponse,
  getUrl,
  printResultData,
} from './utils/getFnByThirdPartyApiConfig';

// 등록된 사용자 체크
const isCheckMember = (passwordNumber) => {
  if (
    passwordNumber !== '2191' &&
    passwordNumber !== '9662' &&
    passwordNumber !== '3790' &&
    passwordNumber !== '6318'
  ) {
    return false;
  }
  return true;
};

// 메시지 생성
const getSendMessage = (data) => {
  let itemTitle = data.goods;

  if (itemTitle.match(/[A-Z]/)) {
    itemTitle = itemTitle.replace(/[A-Z]/g, '');
  } else if (itemTitle.includes('일회용식판')) {
    itemTitle = '일회용식판';
  }

  const normalMessage = `
  안녕하세요, 한통도시락입니다.
  오늘은 인공지능이 계산한 "${itemTitle}"의 예상 구매날짜 입니다.
  재고를 확인 후 구매는 아래 링크를 이용해주세요.
  https://smartstore.naver.com/hantongbox
  
  (주)한통을 이용해 주셔서 감사합니다.
    `;
  const specialMessage = `
  안녕하세요, 한통도시락입니다.
  오늘은 인공지능이 계산한 "${itemTitle}"의 예상 구매날짜 입니다.
  재고를 확인 후 구매는 아래 링크를 이용해주세요.
  https://smartstore.naver.com/hantongbox

  기상악화로 배송지연이 발생할 수 있으니 서둘러 주문을 부탁드립니다.
  
  (주)한통을 이용해 주셔서 감사합니다.
    `;

  const today = dayjs();
  const year = today.year();

  const startDate = dayjs(`${year}-04-01`);
  const endDate = dayjs(`${year}-11-16`);

  const isNormalDate =
    (today.isAfter(startDate) && today.isBefore(endDate)) ||
    today.isSame(startDate);

  return isNormalDate ? normalMessage : specialMessage;
};

// sms 관련 third party api 사용 함수
const sms = () => {
  // 알린다 sms 보내기 api
  const sendAllinda = async (values) => {
    const postData = values;
    const category = 'send_sms';
    const url = getUrl('send_sms', isOwnServerApi);

    try {
      const response = await getResponse(url, postData, isOwnServerApi);
      const result = response;
      printResultData(category, result);
      alert('SMS를 전송하는데 성공했습니다.');
    } catch (err) {
      alert('SMS를 전송하는데 실패했습니다.');
    }
  };

  // 알린다 sms 보내기 (user id 포함) api
  const reqestAllinda = async (phoneNumber, message, password) => {
    const postData = {
      recipients: [{ phoneNumber }],
      contents: message,
    };
    const category = 'send_sms_userid';
    const url = getParamUrl('send_sms_userid', password, isOwnServerApi);
    try {
      const response = await getResponse(url, postData, isOwnServerApi);
      const result = response;
      printResultData(category, result);
      return result;
    } catch (err) {
      return err;
    }
  };

  // payapp 링크 받기 api
  const sendPayapp = async (values) => {
    const postData = values;
    const category = 'payapp';
    const url = getUrl(category, isOwnServerApi);

    try {
      const response = await getResponse(url, postData, isOwnServerApi);
      const { result_data } = response.data;
      const payUrl = decodeURIComponent(result_data.data.pay_url); // decodeURIComponent(result_data.match(/payurl=([^&]*)/)[1]);
      const payCode = result_data.data.mul_no; // new URLSearchParams(result_data).get('mul_no');
      // console.log(`payUrl: ${payUrl}, payCode: ${payCode}`);
      const result = { payUrl, payCode };
      printResultData(category, result);
      return result;
    } catch (err) {
      console.log(err);
      alert('결제링크를 전송하는데 실패했습니다.');
    }
  };

  // 주문제안 api
  const sendProposalAllinda = async (values) => {
    const { password, goodsAndPhoneNum } = values;
    // password 체크
    const isCorrectPw = isCheckMember(password);
    if (!isCorrectPw) {
      return { isSuccess: false, message: 'wrong-password' };
    }
    const resultData = [];
    for (const data of goodsAndPhoneNum) {
      // 메시지 받기
      const message = getSendMessage(data);
      const { phoneNumber, goods } = data;
      const phoneNumberWithoutHyphen = phoneNumber.replace(/-/g, '');
      if (phoneNumberWithoutHyphen.length <= 0 || !phoneNumberWithoutHyphen) {
        return;
      }
      const responseAllinda = await reqestAllinda(
        phoneNumberWithoutHyphen,
        message,
        password
      );
      // client로 보내줄 값 생성
      if (responseAllinda.status === 200) {
        resultData.push({
          isSuccess: true,
          message: 'success',
          goods,
          phoneNumber,
        });
      } else {
        resultData.push({
          isSuccess: false,
          message: 'fail',
          goods,
          phoneNumber,
        });
      }
    }
    return resultData;
  };

  return { sendAllinda, sendPayapp, sendProposalAllinda };
};

export default sms;
