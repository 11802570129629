import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import useSupabase from '../../../serverConfig';

import supabase from '../../../supabase';
import isUserMigrated from '../../../userMigrationConfig';

const initialState = {
  isSuccessSignoutUser: false,
  isLoadingSignoutUser: false,
  isErrorSignoutUser: false,
};

const removeTokenFromLocalStorage = () => {
  localStorage.removeItem('token');
};

// 수파베이스 연결
const signoutBySupabase = async () => {
  await supabase.auth.signOut();
};
// 자체서버 연결 (유저 이식 이후 부터)
const signoutByApi = () => {
  removeTokenFromLocalStorage();
};
// 자체서버 연결 (유저 이식 완료 전까지)
const signoutWithMigrateUser = async () => {
  // 임시 로그인의 경우 temp_email이 로컬 스토리지에 있으므로 삭제
  const existTempEmail = localStorage.getItem('temp_email');
  if (existTempEmail) {
    localStorage.removeItem('temp_email');
  }

  await signoutBySupabase();
  signoutByApi();
};

const asyncSignoutUserFetch = createAsyncThunk(
  'SignoutUserSlice/asyncSignoutUserFetch',
  async (_, { rejectWithValue }) => {
    try {
      if (useSupabase) {
        await signoutBySupabase();
      } else {
        if (isUserMigrated) {
          signoutByApi();
        } else {
          await signoutWithMigrateUser();
        }
      }

      return { isLoggedIn: false };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const signoutUserSlice = createSlice({
  name: 'signoutUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(asyncSignoutUserFetch.pending, (state) => {
      state.isLoadingSignoutUser = true;
    });
    builder.addCase(asyncSignoutUserFetch.fulfilled, (state) => {
      state.isLoadingSignoutUser = false;
      state.isSuccessSignoutUser = true;
    });
    builder.addCase(asyncSignoutUserFetch.rejected, (state) => {
      state.isLoadingSignoutUser = false;
      state.isErrorSignoutUser = true;
    });
  },
});

export default signoutUserSlice.reducer;
export { asyncSignoutUserFetch };
