import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getInventoryOrderSettingApi } from '../apis/inventoryApi';
import { asyncReadDataFetch } from '../redux/modules/shared/readData';

const useSortOrderSetting = (datas) => {
  const dispatch = useDispatch();
  const [sortedDatas, setSortedDatas] = useState([]);
  useEffect(() => {
    const sortDatas = async () => {
      /*
      // 레거시 코드
      const fetchedSortOrderSetting = await dispatch(
        asyncReadDataFetch({ table: 'inventory_order_setting' })
      ).unwrap();
      */
      const resData = await getInventoryOrderSettingApi();
      const fetchedSortOrderSetting = resData;
      const sortOrderMap = new Map(
        fetchedSortOrderSetting.map((item) => [item.product_id, item.order])
      );
      const result = datas
        .filter((data) => sortOrderMap.has(data.product_id))
        .sort(
          (a, b) =>
            sortOrderMap.get(a.product_id) - sortOrderMap.get(b.product_id)
        );
      const remainingDatas = datas.filter(
        (data) => !sortOrderMap.has(data.product_id)
      );
      setSortedDatas([...result, ...remainingDatas]);
    };
    sortDatas();
  }, [datas]);
  return sortedDatas;
};

export default useSortOrderSetting;
