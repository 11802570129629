import React, { useMemo } from 'react';
import { BarChartOutlined } from '@ant-design/icons';

// datepicker 한글화
import { ConfigProvider } from 'antd';
import 'dayjs/plugin/updateLocale';
import 'dayjs/locale/ko';
import locale from 'antd/lib/locale/ko_KR';

import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import Calendar from '../components/Calendar';
import './DataCalendar.css';

const DataCalendar = () => {
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <BarChartOutlined />,
        breadCrumbNav: ' 데이터달력',
      },
    ],
    []
  );

  const pathname = window.location.pathname;

  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <div
        className={
          pathname === '/data-calendar/browse-here' ? 'half_scale' : ''
        }
      >
        <ConfigProvider locale={locale}>
          <Calendar />
        </ConfigProvider>
      </div>
    </LayoutComponent>
  );
};

export default DataCalendar;
