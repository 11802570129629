import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  darkModeColors,
  DARK_MODE_BACKGROUND,
  lightModeColors,
  LIGHT_MODE_BACKGROUND,
  borderRadiuses,
} from '../../constants';
import AbsoluteLeftText from '../../shared/components/AbsoluteLeftText';

const DeliveryStatus = (props) => {
  const history = useHistory();
  const { darkMode } = useSelector((state) => state.darkMode);
  const colors = darkMode ? darkModeColors : lightModeColors;
  const bgColor = darkMode ? DARK_MODE_BACKGROUND : LIGHT_MODE_BACKGROUND;

  const { deliveryStatusData, rangeText } = props;

  return (
    <div
      style={{
        textAlign: 'center',
        padding: '10px',
        color: 'gray',
        borderRadius: borderRadiuses.primary,
        height: '100%',
        backgroundColor: bgColor,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          color: 'gray',
          marginBottom: '5px',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AbsoluteLeftText left="3px" text={`범위 ${rangeText}`} />
        <h4
          onClick={() => history.push('/external-services/delivery-tracker')}
          style={{ cursor: 'pointer' }}
        >
          택배 현황
        </h4>
      </div>
      <Container>
        <LeftSide>
          <InnerContainer color={colors.armsone_blue}>
            <div>준비</div>
            <div>{deliveryStatusData.received} 건</div>
          </InnerContainer>
          <InnerContainer color={colors.armsone_orange}>
            <div>인수</div>
            <div>{deliveryStatusData.atPickup} 건</div>
          </InnerContainer>
          <InnerContainer color={colors.armsone_green}>
            <div>이동</div>
            <div>{deliveryStatusData.inTransit} 건</div>
          </InnerContainer>
          <InnerContainer color={colors.chart_bg_1}>
            <div>출발</div>
            <div>{deliveryStatusData.delivery} 건</div>
          </InnerContainer>
          <InnerContainer>
            <div>완료</div>
            <div>{deliveryStatusData.delivered} 건</div>
          </InnerContainer>
        </LeftSide>
        <SideDivider
          style={{
            borderLeft: darkMode ? '0.5px solid gray' : '0.5px solid lightgray',
          }}
        />
        <RightSide>
          <InnerContainer color={colors.armsone_pink}>
            <div>송장오류</div>
            <div>{deliveryStatusData.errorUnTrackId} 건</div>
          </InnerContainer>
          <InnerContainer color={colors.armsone_pink}>
            <div>미배달</div>
            <div>{deliveryStatusData.errorUnDelivery} 건</div>
          </InnerContainer>
          <InnerContainer color={colors.armsone_red}>
            <div>배송불가</div>
            <div>{deliveryStatusData.errorUnable} 건</div>
          </InnerContainer>
          <InnerContainer>
            {deliveryStatusData.etc > 0 ? (
              <>
                <div>정보없음</div>
                <div>{deliveryStatusData.etc} 건</div>
              </>
            ) : (
              <div style={{ color: bgColor }}>.</div>
            )}
          </InnerContainer>
          <InnerContainer fontWeight="bold">
            <div>합계</div>
            <div>
              {deliveryStatusData.sum &&
                deliveryStatusData.sum.toLocaleString()}{' '}
              건
            </div>
          </InnerContainer>
        </RightSide>
      </Container>
    </div>
  );
};

export default DeliveryStatus;

DeliveryStatus.propTypes = {
  deliveryStatusData: PropTypes.instanceOf(Object).isRequired,
  rangeText: PropTypes.string.isRequired,
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 20px;
  padding: 10px;
  margin-top: 10px;
`;
const LeftSide = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  gap: 5px;
`;
const RightSide = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  gap: 5px;
`;
const SideDivider = styled.div`
  height: 100%;
`;
const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontWeight};
`;
