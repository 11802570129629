import axiosInstance from '../axios';

const getSalesTargetsByYearApi = async (postData) => {
  const { year } = postData;
  const url = `api/data-view/sales-target/${year}`;
  const response = await axiosInstance.get(url);
  return response.data;
};

const foo = () => {};

export { getSalesTargetsByYearApi, foo };
