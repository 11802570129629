import axiosInstance from '../axios';

const getInventoryDataApi = async (postData) => {
  const url = 'api/inventory';
  const response = await axiosInstance.post(url, postData);
  return response.data;
};

const getInventoryDataByIdApi = async (postData) => {
  const url = 'api/inventory/id';
  const response = await axiosInstance.post(url, postData);
  return response.data;
};

const getRecentInventoryDataApi = async (postData) => {
  const url = 'api/inventory/recent';
  const response = await axiosInstance.post(url, postData);
  return response.data;
};

const getStackQuantityApi = async (postData) => {
  const url = 'api/inventory/stack-quantity';
  const response = await axiosInstance.post(url, postData);
  return response.data;
};

const getSafetyStockApi = async (postData) => {
  const url = 'api/inventory/safety-stock';
  const response = await axiosInstance.post(url, postData);
  return response.data;
};

const getChangedStockApi = async (postData) => {
  const url = 'api/inventory/changed-stock';
  const response = await axiosInstance.post(url, postData);
  return response.data;
};

const getInventoryProductSetupApi = async () => {
  const url = 'api/inventory/product-setup';
  const response = await axiosInstance.get(url);
  return response.data;
};

const getInventoryOrderSettingApi = async () => {
  const url = 'api/inventory/order-setting';
  const response = await axiosInstance.get(url);
  return response.data;
};

const getInventorySearchDefaultApi = async () => {
  const url = 'api/inventory/search-default';
  const response = await axiosInstance.get(url);
  return response.data;
};

const createInventoryProductSetupApi = async (postData) => {
  const url = 'api/inventory/product-setup';
  const response = await axiosInstance.post(url, postData);
  return response.data;
};

const deleteInventoryProductSetupApi = async (postData) => {
  const { id } = postData;
  const url = `api/inventory/product-setup/${id}`;
  const response = await axiosInstance.delete(url);
  return response.data;
};

const createInventorySearchDefaultApi = async (postData) => {
  const url = 'api/inventory/search-default';
  const response = await axiosInstance.post(url, postData);
  return response.data;
};

const deleteInventorySearchDefaultApi = async (postData) => {
  const { id } = postData;
  const url = `api/inventory/search-default/${id}`;
  const response = await axiosInstance.delete(url);
  return response.data;
};

const createInventoryOrderSettingApi = async (postData) => {
  const url = 'api/inventory/order-setting';
  const response = await axiosInstance.post(url, postData);
  return response.data;
};

const deleteInventoryOrderSettingApi = async (postData) => {
  const { id } = postData;
  const url = `api/inventory/order-setting/${id}`;
  const response = await axiosInstance.delete(url);
  return response.data;
};

const createInventorySafetyStockApi = async (postData) => {
  const url = `api/inventory/safety-stock/create`;
  const response = await axiosInstance.post(url, postData);
  return response.data;
};

const updateInventorySafetyStockApi = async (postData) => {
  const url = `api/inventory/safety-stock`;
  const response = await axiosInstance.patch(url, postData);
  return response.data;
};

const deleteInventorySafetyStockApi = async (postData) => {
  const { id } = postData;
  const url = `api/inventory/safety-stock/${id}`;
  const response = await axiosInstance.delete(url);
  return response.data;
};

export {
  getInventoryDataApi,
  getInventoryDataByIdApi,
  getRecentInventoryDataApi,
  getStackQuantityApi,
  getSafetyStockApi,
  getChangedStockApi,
  getInventoryProductSetupApi,
  getInventoryOrderSettingApi,
  getInventorySearchDefaultApi,
  createInventoryProductSetupApi,
  deleteInventoryProductSetupApi,
  createInventorySearchDefaultApi,
  deleteInventorySearchDefaultApi,
  createInventoryOrderSettingApi,
  deleteInventoryOrderSettingApi,
  createInventorySafetyStockApi,
  updateInventorySafetyStockApi,
  deleteInventorySafetyStockApi,
};
