import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';

import { inventoryCouse } from '../constants';

import { asyncCreateDataFetch } from '../redux/modules/shared/createData';
import { asyncReadFilteredDataFetch } from '../redux/modules/shared/readFilteredData';
import { asyncReadMatchDataFetch } from '../redux/modules/shared/readMatchData';
import { asyncReadSpecificDataFetch } from '../redux/modules/shared/readSpecificData';

import useQueryFunctions from './useQueryFunctions';

const useChangeInventoryData = () => {
  const dispatch = useDispatch();

  const { fnInsertInventory } = useQueryFunctions();

  // id값을 code로 가져오기
  const getIdFromCode = async ({ code, table }) => {
    try {
      const fetchedData = await dispatch(
        asyncReadFilteredDataFetch({
          table,
          eqKey: 'code',
          eqValue: code,
        })
      )
        .unwrap()
        .then((res) => res[0]);
      const id = fetchedData.id;
      return id;
    } catch (error) {
      throw new Error(error);
    }
  };

  // name값을 id로 가져오기
  const getNameFromId = async ({ id, table }) => {
    try {
      const fetchedData = await dispatch(
        asyncReadFilteredDataFetch({
          table,
          eqKey: 'id',
          eqValue: id,
        })
      )
        .unwrap()
        .then((res) => res[0]);
      const name = fetchedData.name;
      return name;
    } catch (error) {
      throw new Error(error);
    }
  };

  // 재고 변경분 가져오기
  const getChangeQuantity = ({ quantity, eventType }) => {
    let changeQuantity;
    switch (eventType) {
      case 'add':
        changeQuantity = quantity;
        break;
      case 'abstract':
        changeQuantity = -quantity;
        break;
      default:
        return;
    }
    return changeQuantity;
  };

  // 주문서 데이터 페치하기
  const fetchSalesData = async (salesId) => {
    try {
      // 주문서 데이터
      const fetchedSalesData = await dispatch(
        asyncReadSpecificDataFetch({ table: 'order', id: salesId })
      ).unwrap();
      return fetchedSalesData[0];
    } catch (error) {
      throw new Error(error);
    }
  };

  // 판매 데이터 페치하기
  const fetchSalesProductDatas = async (salesId) => {
    try {
      // 판매 품목 데이터
      const fetchedSalesProductDatas = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'order_product',
          eqKey: 'order_id',
          eqValue: salesId,
        })
      ).unwrap();
      return fetchedSalesProductDatas;
    } catch (error) {
      throw new Error(error);
    }
  };

  // 판매 데이터를 재고 데이터에 맞게 포맷하기
  const formatSalesDatas = async ({
    salesId,
    salesData,
    salesProductDatas,
    eventType,
    causeState,
    causeOfChange,
  }) => {
    try {
      // const inventoryChangeDate = dayjs().format('YYYY-MM-DD');
      const adminCode = salesData.admin_code;
      const accountCode = salesData.account_code;
      const adminId = await getIdFromCode({
        code: adminCode,
        table: 'profiles',
      });
      const accountId = await getIdFromCode({
        code: accountCode,
        table: 'account',
      });
      // const paymentTerms = salesData.payment_terms;
      const formattedSalesDatas = await Promise.all(
        salesProductDatas.map(async (salesData) => {
          const quantity = salesData.product_quantity;
          const warehouseCode = salesData.product_warehouse;
          const productCode = salesData.product_code;
          const warehouseId = await getIdFromCode({
            code: warehouseCode,
            table: 'warehouse',
          });
          const productId = await getIdFromCode({
            code: productCode,
            table: 'product',
          });
          const changeQuantity = getChangeQuantity({
            quantity,
            eventType,
          });
          /*
          const stackQuantity = await getStackQuantity({
            productId,
            warehouseId,
            changeQuantity,
          });
          */
          return {
            adminId,
            accountId,
            // paymentTerms,
            // inventoryChangeDate,
            warehouseId,
            productId,
            changeQuantity,
            // stackQuantity,
            causeState,
            causeOfChange,
            causeId: salesId,
          };
        })
      );
      return formattedSalesDatas;
    } catch (error) {
      throw new Error(error);
    }
  };

  // 재고 변동 처리
  const handleInventoryDatas = async (inventoryDatas) => {
    try {
      for (const inventoryData of inventoryDatas) {
        const {
          // inventoryChangeDate,
          changeQuantity,
          // stackQuantity,
          productId,
          warehouseId,
          adminId,
          accountId,
          // paymentTerms,
          causeState,
          causeOfChange,
          causeId,
        } = inventoryData;
        await fnInsertInventory({
          changeQuantity,
          productId,
          warehouseId,
          accountId,
          adminId,
          causeId,
          causeOfChange,
          causeState,
        });

        // 0.001초 대기 (reg_date 소수점 3째 자리 중복 보완)
        await new Promise((_) => setTimeout(_, 1));
      }
      return inventoryDatas;
      /*
      레거시 코드
      await Promise.all(
        inventoryDatas.forEach(async (inventoryData) => {
          console.log(inventoryData);
          const {
            // inventoryChangeDate,
            changeQuantity,
            // stackQuantity,
            productId,
            warehouseId,
            adminId,
            accountId,
            // paymentTerms,
            causeState,
            causeOfChange,
            causeId,
          } = inventoryData;
          await fnInsertInventory({
            changeQuantity,
            productId,
            warehouseId,
            accountId,
            adminId,
            causeId,
            causeOfChange,
            causeState,
          });
          const postData = {
            inventory_change_date: inventoryChangeDate,
            change_quantity: changeQuantity,
            stack_quantity: stackQuantity,
            product_id: productId,
            warehouse_id: warehouseId,
            admin_id: adminId,
            account_id: accountId,
            payment_terms: paymentTerms,
            cause_state: causeState,
            cause_of_change: causeOfChange,
            cause_id: causeId,
          };
          const handledInventoryData = await dispatch(
            asyncCreateDataFetch({ table: 'inventory', ...postData })
          )
            .unwrap()
            .then((res) => res[0]);
          return handledInventoryData;
          
        })
      );
      return handledInventoryDatas;
      */
    } catch (error) {
      throw new Error(error);
    }
  };

  // 재고 변동 처리된 데이터의 이름 정보 추가
  const getInventoryDatasWithNameInfo = async (inventoryDatas) => {
    try {
      const inventoryDatasWithNameInfo = await Promise.all(
        inventoryDatas.map(async (inventoryData) => {
          const { productId, warehouseId } = inventoryData;
          const productName = await getNameFromId({
            id: productId,
            table: 'product',
          });
          const warehouseName = await getNameFromId({
            id: warehouseId,
            table: 'warehouse',
          });

          return { ...inventoryData, productName, warehouseName };
        })
      );
      return inventoryDatasWithNameInfo;
    } catch (error) {
      throw new Error(error);
    }
  };

  /*
  // 각 이벤트 함수 추상화
  const processInventory = async ({
    salesInfo,
    eventType,
    causeState,
    causeOfChange,
  }) => {
    try {
      let formattedSalesDatas;
      // 인자가 id값인지 datas값인지
      const isSalesInfoId = typeof salesInfo === 'string';
      if (isSalesInfoId) {
        const salesId = salesInfo;
        // 판매 정보 불러오기 (accountId, adminId, paymentTerms)
        const fetchedSalesData = await fetchSalesData(salesId);
        // 판매 품목 정보 불러오기 (productId, warehouseId, quantity)
        const fetchedSalesProductDatas = await fetchSalesProductDatas(salesId);
        // 판매 정보 포맷팅
        formattedSalesDatas = await formatSalesDatas({
          salesId,
          salesData: fetchedSalesData,
          salesProductDatas: fetchedSalesProductDatas,
          eventType,
          causeState,
          causeOfChange,
        });
        console.log(formattedSalesDatas);
      } else {
        const salesId = salesInfo[0].order_id;
        console.log(salesId, salesInfo);
        // 판매 정보 불러오기 (accountId, adminId, paymentTerms)
        const fetchedSalesData = await fetchSalesData(salesId);
        // 품목 정보 포맷팅
        formattedSalesDatas = await formatSalesDatas({
          salesId,
          salesData: fetchedSalesData,
          salesProductDatas: salesInfo,
          eventType,
          causeState,
          causeOfChange,
        });
        console.log(formattedSalesDatas);
      }
      // 재고 변동 처리하기
      const inventoryDatas = await handleInventoryDatas(formattedSalesDatas);
      // 처리된 재고데이터를 추가 정보와 함께 가져오기
      const inventoryDatasWithNameInfo = await getInventoryDatasWithNameInfo(
        inventoryDatas
      );
      return inventoryDatasWithNameInfo;
    } catch (error) {
      throw new Error(error);
    }
  };
  */

  const processInventoryByAddOrAbstract = async ({
    salesId,
    eventType,
    causeState,
    causeOfChange,
  }) => {
    try {
      // 판매 정보 불러오기 (accountId, adminId, paymentTerms)
      const fetchedSalesData = await fetchSalesData(salesId);
      // 판매 품목 정보 불러오기 (productId, warehouseId, quantity)
      const fetchedSalesProductDatas = await fetchSalesProductDatas(salesId);
      // 판매 정보 포맷팅
      const formattedSalesDatas = await formatSalesDatas({
        salesId,
        salesData: fetchedSalesData,
        salesProductDatas: fetchedSalesProductDatas,
        eventType,
        causeState,
        causeOfChange,
      });
      // 재고 변동 처리하기
      const inventoryDatas = await handleInventoryDatas(formattedSalesDatas);
      // 처리된 재고데이터를 추가 정보와 함께 가져오기
      const inventoryDatasWithNameInfo = await getInventoryDatasWithNameInfo(
        inventoryDatas
      );
      return inventoryDatasWithNameInfo;
    } catch (error) {
      throw new Error(error);
    }
  };

  const processInventoryByChange = async ({
    salesId,
    salesData,
    salesProductDatas,
    eventType,
    causeState,
    causeOfChange,
  }) => {
    // 인자가 id값인지 datas값인지
    try {
      let formattedSalesDatas;
      const isExistSalesProductDatas = !!salesProductDatas;
      if (!isExistSalesProductDatas) {
        // 판매 품목 정보 불러오기 (productId, warehouseId, quantity)
        const fetchedSalesProductDatas = await fetchSalesProductDatas(salesId);
        // 판매 정보 포맷팅
        formattedSalesDatas = await formatSalesDatas({
          salesId,
          salesData,
          salesProductDatas: fetchedSalesProductDatas,
          eventType,
          causeState,
          causeOfChange,
        });
      } else {
        // 판매 정보 포맷팅
        formattedSalesDatas = await formatSalesDatas({
          salesId,
          salesData,
          salesProductDatas,
          eventType,
          causeState,
          causeOfChange,
        });
      }
      // 재고 변동 처리하기
      const inventoryDatas = await handleInventoryDatas(formattedSalesDatas);
      // 처리된 재고데이터를 추가 정보와 함께 가져오기
      const inventoryDatasWithNameInfo = await getInventoryDatasWithNameInfo(
        inventoryDatas
      );
      return inventoryDatasWithNameInfo;
    } catch (error) {
      throw new Error(error);
    }
  };

  // 판매 삭제시 (재고 증가)
  const addInventoryBySales = async (salesId) => {
    try {
      const addedInventoryDatas = await processInventoryByAddOrAbstract({
        salesId,
        eventType: 'add',
        causeState: inventoryCouse.state_delete,
        causeOfChange: inventoryCouse.of_change_sales,
      });
      return addedInventoryDatas;
    } catch (error) {
      throw new Error(error);
    }
  };

  // 판매 입력시 (재고 감소)
  const abstractInventoryBySales = async (salesId) => {
    try {
      const abstractedInventoryDatas = await processInventoryByAddOrAbstract({
        salesId,
        eventType: 'abstract',
        causeState: inventoryCouse.state_create,
        causeOfChange: inventoryCouse.of_change_sales,
      });
      return abstractedInventoryDatas;
    } catch (error) {
      throw new Error(error);
    }
  };

  // 판매 수정시 (재고 수정)
  const changeInventoryBySales = async ({
    originalSalesId,
    newSalesDatas,
    adminCode,
    accountCode,
    originalPaymentTerms,
    newPaymentTerms,
  }) => {
    try {
      const addedInventoryDatas = await processInventoryByChange({
        salesId: originalSalesId,
        salesData: {
          admin_code: adminCode,
          account_code: accountCode,
          payment_terms: originalPaymentTerms,
        },
        salesProductDatas: null,
        eventType: 'add',
        causeState: inventoryCouse.state_update,
        causeOfChange: inventoryCouse.of_change_sales,
      });
      const abstractedInventoryDatas = await processInventoryByChange({
        salesId: originalSalesId,
        salesData: {
          admin_code: adminCode,
          account_code: accountCode,
          payment_terms: newPaymentTerms,
        },
        salesProductDatas: newSalesDatas,
        eventType: 'abstract',
        causeState: inventoryCouse.state_update,
        causeOfChange: inventoryCouse.of_change_sales,
      });
      return { addedInventoryDatas, abstractedInventoryDatas };
    } catch (error) {
      throw new Error(error);
    }
  };

  return {
    addInventoryBySales,
    abstractInventoryBySales,
    changeInventoryBySales,
  };
};

export default useChangeInventoryData;

// 변동 데이터에서 펑션 호출
