import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  asyncSigninUserFetch,
  logoutReducer,
} from '../../redux/modules/auth/signinUser';
import { asyncSignoutUserFetch } from '../../redux/modules/auth/signoutUser';

import useModal from '../../hook/useModal';
import Center from '../../shared/components/Center';
import AuthContainer from '../components/AuthContainer';
import AuthFooter from '../components/AuthFooter';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { openModal } = useModal();

  const handleSubmit = async (values) => {
    try {
      await dispatch(asyncSigninUserFetch(values))
        .unwrap()
        .then((res) => {
          const isSBLoginSuccess = !!res === true;
          if (isSBLoginSuccess) {
            // 서버측 migrateUser api호출
            history.push('/data-calendar');
          }
        })
        .catch(() => {
          openModal({
            type: 'result',
            modalInfo: {
              modalTitle: '로그인',
              resultTitle: '로그인할 수 없습니다.',
              resultStatus: 'error',
              resultSubTitle:
                '이메일 또는 비밀번호를 확인한 후 다시 입력해주세요.',
              buttonText: '확인',
            },
          });
        });
    } catch (error) {
      alert(error);
    }
  };

  // 임시 로그인 상태
  const [isTempLogin, setIsTempLogin] = useState(false);
  const [tempEmail, setTempEmail] = useState(null);
  const [tempPass, setTempPass] = useState(null);

  // 임시 로그인
  const handleTempLogin = async () => {
    try {
      const values = { email: tempEmail, password: tempPass, isTempLogin };
      await dispatch(asyncSigninUserFetch(values)).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Center>
      <div style={{ fontSize: '32px', fontFamily: 'Jua', color: 'white' }}>
        한통 OMS
      </div>
      <div style={{ marginTop: '5px' }} onClick={() => setIsTempLogin(true)}>
        한통 OMS : 자체서버 ver.에 오신걸 환영합니다 !
      </div>
      <AuthContainer handleSubmit={handleSubmit} signup={false} />
      <AuthFooter />
      {isTempLogin && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '20px',
            gap: '5px',
          }}
        >
          <input
            onChange={(e) => setTempEmail(e.target.value)}
            value={tempEmail}
          />
          <input
            onChange={(e) => setTempPass(e.target.value)}
            value={tempPass}
          />
          <button onClick={handleTempLogin}>로그인</button>
        </div>
      )}
    </Center>
  );
};

export default Login;
