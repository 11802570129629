import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Descriptions,
  Radio,
  Upload,
  DatePicker,
  ConfigProvider,
  Select,
  Tag,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  ReloadOutlined,
  UnorderedListOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// datepicker 한글화
import 'dayjs/plugin/updateLocale';
import 'dayjs/locale/ko';
import locale from 'antd/lib/locale/ko_KR';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';

import AddressForm from './AddressForm';
import RespForm from './RespForm';

const DescriptionForm = (props) => {
  const {
    datas,
    setDatas,
    descriptionDatas,
    onFinish,
    pageType,
    addressDatas,
    setAddressDatas,
    addressLoading,
    respDatas,
    setRespDatas,
  } = props;

  const history = useHistory();
  const { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  // 사업장 번호 인풋 부분 disable 처리
  const [isDisableBn, setIsDisableBn] = useState(false);

  // 이카운트 변경 관련 alert 문자 띄움
  const [isChangeEcountCode, setIsChangeEcountCode] = useState(false);

  // 데이터 불러오기
  useEffect(() => {
    // 코드생성
    if (pageType === 'create') {
      const asyncSetFieldsFunc = async () => {
        const len = await dispatch(
          asyncReadDataFetch({ table: 'account', reqDataLen: true })
        )
          .unwrap()
          .then((res) => res + 1)
          .catch();
        const accountCode = `A${len.toString().padStart(6, '0')}`;
        form.setFieldsValue({
          // 거래처 코드에 A코드 부여하기
          code: accountCode,
          // 샘플거래처 기본 값 OFF
          is_sample: false,
          ...datas,
        });
      };
      asyncSetFieldsFunc();
    }

    if (pageType === 'update' || pageType === 'detail') {
      if (datas.is_sample) {
        setIsDisableBn(true);
      }
      if (!datas.is_sample && Object.keys(datas).length !== 0) {
        form.setFieldsValue({
          business_number: datas.ecount_code,
          ...datas,
        });
      } else {
        form.setFieldsValue({
          ...datas,
        });
      }
    }
  }, [datas, form, pageType, dispatch, setDatas]);

  /*
  // 날짜 form datas
  const onChange = useCallback(
    (date, dateString) => {
      setDatas((prevState) => {
        return {
          ...prevState,
          first_sale: dateString,
        };
      });
    },
    [setDatas]
  );
  */

  // input type = number의 경우 글자수 제한
  const maxLengthCheck = (e) => {
    if (pageType === 'update') {
      setIsChangeEcountCode(true);
    }
    if (e.target.value.length >= e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength); // eslint-disable-line no-param-reassign
    }
  };

  // 샘플거래처 on -> 사업장 번호 disable 처리
  const handleIsSample = useCallback(
    (e) => {
      if (pageType === 'update') {
        setIsChangeEcountCode(true);
      }
      if (e) {
        form.setFieldsValue({ business_number: '' });
        setIsDisableBn(true);
      } else {
        setIsDisableBn(false);
      }
    },
    [form, pageType]
  );

  return (
    <>
      <div
        style={{
          fontSize: '16px',
          paddingBottom: '8px',
          borderBottom: '1px solid gray',
        }}
      >
        거래처 입력
      </div>
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        labelCol={{
          span: 24,
        }}
      >
        <Descriptions
          bordered
          labelStyle={{ width: '10%', textAlign: 'center' }}
        >
          {descriptionDatas.map((descriptionData) => {
            if (descriptionData.type === 'input') {
              return (
                <Descriptions.Item
                  key={descriptionData.name}
                  label={
                    descriptionData.name === 'code' ||
                    descriptionData.name === 'name' ? (
                      <>
                        <span style={{ color: `red` }}>* </span>
                        <span>{descriptionData.label}</span>
                      </>
                    ) : (
                      descriptionData.label
                    )
                  }
                  span={descriptionData.span}
                >
                  {descriptionData.name === 'business_number' && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '5px',
                      }}
                    >
                      <Tag
                        color={isDisableBn ? 'red' : 'blue'}
                        style={{ marginRight: '10px', borderRadius: '0px' }}
                      >
                        {isDisableBn ? '샘플거래처 ON' : '샘플거래처 OFF'}
                      </Tag>
                      {isDisableBn && (
                        <div>
                          <p style={{ color: 'red' }}>
                            샘플거래처가 ON 상태일 때 사업자번호를 입력할 수
                            없습니다.
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                  <Form.Item name={descriptionData.name} noStyle>
                    <Input
                      disabled={
                        pageType === 'detail' ||
                        (isDisableBn &&
                          descriptionData.name === 'business_number') ||
                        (pageType === 'create' &&
                          descriptionData.name === 'code') ||
                        descriptionData.name === 'ecount_code'
                      }
                      placeholder={
                        descriptionData.placeholder ||
                        (descriptionData.name === 'business_number' &&
                          '샘플거래처가 ON 상태일 때 사업자번호를 입력할 수 없습니다.')
                      }
                      type={
                        descriptionData.inputType.length > 0 &&
                        descriptionData.inputType
                      }
                      maxLength={
                        descriptionData.name === 'tex_reg_number' ? 4 : 500
                      }
                      onInput={maxLengthCheck}
                      style={{
                        borderRadius:
                          descriptionData.name === 'briefs' ? '7px' : '0px',
                        padding:
                          descriptionData.name === 'briefs' && '8px 15px',
                      }}
                    />
                  </Form.Item>
                </Descriptions.Item>
              );
            } else if (descriptionData.type === 'radio') {
              return (
                <Descriptions.Item
                  key={descriptionData.name}
                  label={descriptionData.label}
                  span={descriptionData.span}
                >
                  <Form.Item name={descriptionData.name} noStyle>
                    <Radio.Group
                      style={{ display: 'flex', flexDirection: 'column' }}
                      initialValues={
                        descriptionData.name === 'code_sortation' &&
                        '사업자등록번호'
                      }
                    >
                      {descriptionData.options.map((option, index) => {
                        return (
                          <Radio
                            key={index}
                            disabled={pageType === 'detail'}
                            value={option.value}
                            style={{ padding: '5px 0', width: 'fit-content' }}
                          >
                            {option.label}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                  </Form.Item>
                </Descriptions.Item>
              );
            } else if (descriptionData.type === 'file') {
              return (
                <Descriptions.Item
                  key={descriptionData.name}
                  label={descriptionData.label}
                  span={descriptionData.span}
                >
                  <Form.Item name={descriptionData.name} noStyle>
                    <Upload disabled={pageType === 'detail'}>
                      <Button
                        disabled={pageType === 'detail'}
                        icon={<UploadOutlined />}
                        style={{ borderRadius: '0px' }}
                      >
                        파일 업로드
                      </Button>
                    </Upload>
                  </Form.Item>
                </Descriptions.Item>
              );
            } else if (descriptionData.type === 'select') {
              /* 
            else if (descriptionData.type === 'date') {
              return (
                <Descriptions.Item
                  key={descriptionData.name}
                  label={descriptionData.label}
                  span={descriptionData.span}
                >
                  <div style={{ display: 'flex', width: '100%' }}>
                    <ConfigProvider locale={locale}>
                      <DatePicker
                        disabled={pageType === 'detail'}
                        onChange={onChange}
                        locale={locale}
                        style={{ width: '120px', borderRadius: '0px' }}
                      />
                    </ConfigProvider>
                    <Form.Item name={descriptionData.name} noStyle>
                      <Input
                        disabled
                        bordered={false}
                        style={{
                          width: '120px',
                          textAlign: 'right',
                          color: '#1890ff',
                        }}
                      />
                    </Form.Item>
                  </div>
                </Descriptions.Item>
              );
            }
            */
              return (
                <Descriptions.Item
                  key={descriptionData.name}
                  label={
                    descriptionData.name === 'is_sample' ? (
                      <>
                        <span style={{ color: `red` }}>* </span>
                        <span>{descriptionData.label}</span>
                      </>
                    ) : (
                      descriptionData.label
                    )
                  }
                  span={descriptionData.span}
                >
                  <div className="select-container">
                    <Form.Item name={descriptionData.name} noStyle>
                      <Select
                        disabled={pageType === 'detail'}
                        options={descriptionData.options}
                        style={{ width: '100%' }}
                        placeholder={descriptionData.placeholder}
                        onChange={
                          descriptionData.name === 'is_sample'
                            ? handleIsSample
                            : (e) => console.log(e)
                        }
                        showSearch={descriptionData.name === 'region'}
                        filterOption={(input, option) => {
                          return option.label && option.label.includes(input);
                        }}
                      />
                    </Form.Item>
                  </div>
                </Descriptions.Item>
              );
            } else if (descriptionData.type === 'address') {
              return (
                <Descriptions.Item
                  key={descriptionData.name}
                  label={descriptionData.label}
                  span={descriptionData.span}
                >
                  <AddressForm
                    addressDatas={addressDatas}
                    setAddressDatas={setAddressDatas}
                    pageType={pageType}
                    addressLoading={addressLoading}
                  />
                </Descriptions.Item>
              );
            } else if (descriptionData.type === 'resp') {
              return (
                <Descriptions.Item
                  key={descriptionData.name}
                  label={descriptionData.label}
                  span={descriptionData.span}
                >
                  <div>
                    담당자 인원수: <Tag color="blue">{respDatas.length}명 </Tag>
                  </div>
                  <RespForm
                    respDatas={respDatas}
                    setRespDatas={setRespDatas}
                    pageType={pageType}
                  />
                </Descriptions.Item>
              );
            }
          })}
        </Descriptions>
        {isChangeEcountCode && (
          <div style={{ marginTop: '5px', color: 'gray' }}>
            거래처의 샘플거래처 ON/OFF 변경 또는 사업자번호를 입력 후 저장하시면
            이카운트코드가 변경됩니다. 데이터 싱크를 위해 Ecount ERP의 해당
            거래처 코드도 변경된 이카운트코드에 맞게 수정해주세요.
          </div>
        )}
        <div
          style={{
            backgroundColor: '#fafafa',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            borderRadius: '8px',
            border: '1px solid rgb(240, 239, 239)',
            marginTop: '50px',
            padding: '20px 15px',
            position: 'relative',
          }}
        >
          <div>
            <Button
              onClick={() => history.push('/account')}
              style={{ marginRight: '5px' }}
            >
              <UnorderedListOutlined />
              목록
            </Button>
            <Button onClick={() => window.location.reload()}>
              <ReloadOutlined />
              취소
            </Button>
          </div>
          <Form.Item
            style={{
              position: 'absolute',
              bottom: '0px',
              right: '24px',
              backgroundColor: '#fafafa',
            }}
          >
            {pageType === 'detail' && (
              <div>
                <Button
                  onClick={() => history.push(`/account/updateAccount/${id}`)}
                  icon={<EditOutlined />}
                  type="primary"
                  style={{ marginRight: '5px' }}
                >
                  수정
                </Button>
                <Button
                  htmlType="submit"
                  icon={<DeleteOutlined />}
                  type="primary"
                  danger
                >
                  삭제
                </Button>
              </div>
            )}
            {pageType === 'create' && (
              <Button htmlType="submit" icon={<EditOutlined />} type="primary">
                추가
              </Button>
            )}
            {pageType === 'update' && (
              <Button htmlType="submit" icon={<EditOutlined />} type="primary">
                저장
              </Button>
            )}
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default React.memo(DescriptionForm);

DescriptionForm.propTypes = {
  descriptionDatas: PropTypes.instanceOf(Array).isRequired,
  datas: PropTypes.instanceOf(Object).isRequired,
  setDatas: PropTypes.func,
  onFinish: PropTypes.func.isRequired,
  pageType: PropTypes.string.isRequired,
  addressDatas: PropTypes.instanceOf(Array).isRequired,
  setAddressDatas: PropTypes.func.isRequired,
  addressLoading: PropTypes.bool.isRequired,
  respDatas: PropTypes.instanceOf(Array).isRequired,
  setRespDatas: PropTypes.func.isRequired,
};
